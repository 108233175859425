import React, { useState } from 'react';
import { FaTimes, FaEye, FaEyeSlash } from 'react-icons/fa';
import MemberImg from './member.svg';
import { login, requestPasswordReset } from '../utils/auth';
import { toast } from 'react-toastify';
import SubscriptionPopup from './SubscriptionPopup';

const SignInPopup = ({ onClose, onSignUpClick, onSubscribeClick, onSignIn }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [forgotPasswordMode, setForgotPasswordMode] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [showSubscriptionPopup, setShowSubscriptionPopup] = useState(false);

  const handleSignIn = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    try {
      const userData = await login(username, password);
      onSignIn(userData);
    } catch (error) {
      console.error('Sign in error:', error);
      const errorMessage = error.response?.data?.error || error.message || 'An unexpected error occurred. Please try again.';
      setError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await requestPasswordReset(resetEmail);
      toast.success('Password reset email sent. Please check your inbox.');
      setForgotPasswordMode(false);
    } catch (error) {
      console.error('Password reset request error:', error);
      toast.error(error.message || 'An error occurred while sending the reset email.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignUpClick = () => {
    onClose();
    if (typeof onSignUpClick === 'function') {
      onSignUpClick();
    } else {
      console.warn('onSignUpClick is not a function');
    }
  };

  const handleSubscribeClick = () => {
    onClose();
    if (typeof onSubscribeClick === 'function') {
      onSubscribeClick();
    } else {
      console.warn('onSubscribeClick is not a function');
      setShowSubscriptionPopup(true);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <div className="popup-content">
          {!forgotPasswordMode ? (
            <>
              <h2 className="signin-title">Log In</h2>
              <form onSubmit={handleSignIn} className="signin-form">
                <div className="signin-input-group">
                  <input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                    className="signin-input"
                  />
                </div>
                <div className="signin-input-group password-input-group">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="signin-input"
                  />
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="password-toggle-btn"
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </button>
                </div>
                <button type="submit" className="signin-button" disabled={isLoading}>
                  {isLoading ? 'Signing In...' : 'Sign In'}
                </button>
              </form>
              <p className="forgot-password-link">
                <a href="#" onClick={() => setForgotPasswordMode(true)}>Forgot Password?</a>
              </p>
              <p className="signup-link">Don't have an account? <a href="#" onClick={handleSignUpClick}>Sign Up</a></p>
            </>
          ) : (
            <>
              <h2 className="signin-title">Reset Password</h2>
              <form onSubmit={handleForgotPassword} className="signin-form">
                <div className="signin-input-group">
                  <input
                    type="email"
                    placeholder="Email"
                    value={resetEmail}
                    onChange={(e) => setResetEmail(e.target.value)}
                    required
                    className="signin-input"
                  />
                </div>
                <button type="submit" className="signin-button" disabled={isLoading}>
                  {isLoading ? 'Sending...' : 'Send Reset Email'}
                </button>
              </form>
              <p className="back-to-login-link">
                <a href="#" onClick={() => setForgotPasswordMode(false)}>Back to Login</a>
              </p>
            </>
          )}

          <div className="membership-section">
            <img src={MemberImg} alt="Membership" className="membership-image" />
            <div className='membership-content'>
              <p>Become a Member for Exclusive Benefits</p>
              <button className="subscribe-button" onClick={handleSubscribeClick}>Subscribe Now →</button>
            </div>
          </div>
        </div>
        <div className="popup-right">
          <button className="close-button" onClick={onClose}>
            <FaTimes />
          </button>
          <div className="popup-right-content">
            <p className="filler-text">Discover the world of espionage and intrigue.</p>
          </div>
        </div>
      </div>
      {showSubscriptionPopup && (
        <SubscriptionPopup
          onClose={() => setShowSubscriptionPopup(false)}
          onSubscribe={() => {
            setShowSubscriptionPopup(true);
          }}
        />
      )}
    </div>
  );
};

export default SignInPopup;