import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Button, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import { FaEnvelope, FaDownload, FaExternalLinkAlt, FaEdit, FaTrash, FaHeart, FaQrcode, FaUser } from 'react-icons/fa';
import { FaBed, FaBath, FaRulerCombined } from 'react-icons/fa';
import { MdVerified } from 'react-icons/md';
import Alert from 'react-bootstrap/Alert';
import styled from 'styled-components';
import { fetchPropertyQRCode, getFolders } from '../utils/auth';
import placeholderImage from './placeholder.svg';
import { Link } from 'react-router-dom';
import PropertyFolders from './Folders';
import {usePropertySavedState} from './hooks/usePropertySavedState'; 
import { Heart } from 'lucide-react'; 



const StyledButton = styled(Button)`
  background-color: var(--color-blue-main);
  color: #fff;
  border-color: var(--color-blue-main);
  &:hover, &:focus, &:active {
    background-color: var(--color-blue-main);
    border-color: var(--color-blue-main);
    opacity: 0.9;
  }
`;
const PropertyDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 1rem;
  color: #666;
  font-size: 0.95rem;

  .details-wrapper {
    display: flex;
    flex-wrap: ${props => props.hasUrl ? 'wrap' : 'nowrap'};
    gap: ${props => props.hasUrl ? '0px' : '20px'};
    flex: 1;
  }

  .basic-details {
    display: flex;
    align-items: center;
    gap: 20px;
    ${props => props.hasUrl && `
      width: 100%;
      margin-bottom: 8px;
    `}
  }

  .sqft-details {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .detail-item {
    display: flex;
    align-items: center;
    gap: 5px;

    svg {
      color: var(--color-blue-main);
      font-size: 1.1rem;
    }
  }
`;

const PropertyUrl = styled.a`
  display: inline-flex;
  align-items: center;
  gap: 5px;
  color: var(--color-blue-main);
  text-decoration: none;
  font-size: 1.1rem;
  padding: 5px;
  border-radius: 4px;
  transition: all 0.2s ease;

  &:hover {
    background-color: var(--color-blue-main);
    color: white;
  }
`;

const QRCodeModal = styled(Modal)`
  .modal-content {
    border-radius: 10px;
  }
  .modal-body {
    text-align: center;
    img {
      max-width: 100%;
      height: auto;
    }
  }
`;

const StyledCard = styled(Card)`
  .card-img-top {
    height: 250px;
    object-fit: cover;
  }
  .card-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  .card-text {
    margin-bottom: 1rem;
  }
  .property-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;

    span {
      word-wrap: break-word;
      max-width: 50%;
      text-align: left;
    }
  }
  .property-description {
    text-align: left;
    margin-bottom: 1.5rem;
  }
  .address-description {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 1.5rem;
  }
  .address-description div {
    flex: 1 1 45%;
    word-wrap: break-word;
    text-align: left;
  }
  .address-description a {
    flex: 0 0 auto;
    text-align: right;
    white-space: nowrap;
    margin-left: auto;
  }
  .verified-icon {
    color: green;
    margin-left: 5px;
  }
`;

const TruncatedDescription = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.5;
  margin-bottom: 0.5rem;
`;

const SeeMoreButton = styled.button`
  background: none;
  border: none;
  color: var(--color-blue-main);
  padding: 0;
  font-size: 0.9rem;
  cursor: pointer;
  text-decoration: underline;
  margin-bottom: 1rem;

  &:hover {
    opacity: 0.8;
  }
`;

const AgentInfo = styled(Card.Text)`
  border-top: 1px solid #eee;
  padding-top: 12px;
  margin-top: 12px;
  margin-bottom: 0; 
  display: flex;
  justify-content: space-between;
  align-items: center;

  .agent-name-section {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .agent-details {
    text-align: right;
    color: #666;
    font-size: 0.9rem;
  }
`;

const BlurredAmount = styled.span`
  filter: blur(5px);
  user-select: none;
`;

const HeartButton = styled(Button)`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  z-index: 2;

  svg {
    color: ${props => props.isSaved ? '#dc3545' : '#6c757d'};
    transition: all 0.2s ease;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.95);
    transform: scale(1.1);
    
    svg {
      color: ${props => props.isSaved ? '#dc3545' : '#495057'};
    }
  }
`;

const PropertyCard = ({ 
  property, 
  viewMode, 
  isSubscribed, 
  onEmailClick, 
  onDocumentDownload, 
  onEdit, 
  onDelete, 
  currentUser,
  isUploadSection = false,
  isSaved = false,
  setIsSaved
}) => {
  const [showQRCode, setShowQRCode] = useState(false);
  const [qrCodeUrl, setQRCodeUrl] = useState(null);
  const [error, setError] = useState(null);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const [showFolderModal, setShowFolderModal] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);


  const checkIfPropertyInFolders = async () => {
    if (!currentUser) return;
    
    try {
      const foldersData = await getFolders();
      const isInAnyFolder = foldersData.some(folder => 
        folder.property_count > 0 && 
        folder.properties?.some(p => p.property_details?.id === property.id)
      );
      setIsFavorite(isInAnyFolder);
    } catch (err) {
      console.error('Error checking folders:', err);
    }
  };

  useEffect(() => {
    checkIfPropertyInFolders();
  }, [property.id, currentUser]);

  const handleFavoriteClick = () => {
    if (!currentUser) return;
    setShowFolderModal(true);
  };

  const handleShowQRCode = async () => {
    try {
      console.log('Requesting QR code for property:', property);
      const response = await fetchPropertyQRCode(property.id);
      console.log('QR code response received:', response);
      
      if (!response.qr_code_url) {
        console.error('No QR code URL in response:', response);
        throw new Error('QR code URL not found in response');
      }
      
      const qrCodeUrl = response.qr_code_url.startsWith('http') 
        ? response.qr_code_url 
        : `${process.env.REACT_APP_API_URL}${response.qr_code_url}`;
      
      console.log('Setting QR code URL:', qrCodeUrl);
      setQRCodeUrl(qrCodeUrl);
      setShowQRCode(true);
    } catch (error) {
      console.error('Error showing QR code:', error);
      setError(error.message || 'Failed to load QR code');
    }
  };
  const handleModalClose = () => {
    setShowFolderModal(false);
    checkIfPropertyInFolders(); 
  };

  const handleDownloadError = (error) => {
    if (error.message.includes('location restrictions')) {
      setError('You cannot access this property due to location restrictions.');
    } else {
      setError('Failed to download document. Please try again later.');
    }
  };

  const formatNumber = (num) => {
    return Math.floor(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const formatConcession = (concession, type) => {
    if (type === 'dollar' || type === undefined) {
      return `$${formatNumber(concession)}`;
    } else {
      return `${concession}%`;
    }
  };

  const QRCodeDisplay = ({ url }) => {
    if (error) {
      return <Alert variant="danger">{error}</Alert>;
    }
    
    if (!url) {
      return <div>Loading QR code...</div>;
    }

    console.log('Rendering QR code image with URL:', url);
    return (
      <img 
        src={url} 
        alt="Property QR Code" 
        onError={(e) => {
          console.error('QR code image failed to load:', e);
          setError('Failed to load QR code image');
        }}
      />
    );
  };

  const renderConcession = () => {
    if (property.concession === null || property.concession === undefined) return null;

    const concessionAmount = formatConcession(property.concession, property.concession_type);
    const concessionText = `Compensation: `;

    if (isSubscribed) {
      return <div>{concessionText}{concessionAmount}</div>;
    } else {
      return (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="subscribe-tooltip">Subscribe to view compensation</Tooltip>}
        >
          <div>
            {concessionText}
            <BlurredAmount>{concessionAmount}</BlurredAmount>
          </div>
        </OverlayTrigger>
      );
    }
  };

  const isVerified = 
    property.street_address && 
    property.city && 
    property.state &&
    property.zip_code &&
    property.price &&
    (property.bedrooms !== null && property.bedrooms !== undefined) &&
    (property.bathrooms !== null && property.bathrooms !== undefined) &&
    property.sq_ft && 
    property.description && 
    property.pdf_file && 
    property.image &&
    (property.concession !== null && property.concession !== undefined);

  const handleEmailClick = () => {
    if (!onEmailClick) return;
    onEmailClick(property);
  };

  return (
    <>
            <StyledCard className={viewMode === 'list' ? 'mb-3' : 'h-100'}>
        <Row className={viewMode === 'list' ? 'g-0' : ''}>
          <Col md={viewMode === 'list' ? 4 : 12}>
            <div style={{ position: 'relative' }}>
            <HeartButton
  onClick={handleFavoriteClick}
  isSaved={isSaved}
>
  <Heart size={20} fill={isSaved ? '#dc3545' : 'none'} />
</HeartButton>
              <Card.Img
                variant="top"
                src={property.image ? property.image : placeholderImage}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = placeholderImage;
                }}
              />
            </div>
          </Col>
          <Col md={viewMode === 'list' ? 8 : 12}>
            <Card.Body>
              <Card.Title className="d-flex flex-row justify-content-between align-items-center">
                <div>
                  {property.isEmpty ? (
                    <strong>Price not available</strong>
                  ) : (
                    <strong>${formatNumber(property.price)}</strong>
                  )}
                  {isVerified && <MdVerified className="verified-icon ms-2" />}
                </div>
                {renderConcession()}
              </Card.Title>
              
              <Card.Text className="property-details">
                <span>
                  {property.street_address && `${property.street_address}, `}
                  {property.city && `${property.city}, `}
                  {property.state && `${property.state}, `}
                  {property.zip_code}
                </span>
                <StyledButton variant="outline-primary" size="sm" onClick={handleEmailClick}>
                  <FaEnvelope /> Email
                </StyledButton>
              </Card.Text>

              <PropertyDetails hasUrl={!!property.next_property_url}>
  <div className="details-wrapper">
    <div className="basic-details">
      {property.bedrooms && (
        <div className="detail-item">
          <FaBed />
          <span>{property.bedrooms} bed</span>
        </div>
      )}
      {property.bathrooms && (
        <div className="detail-item">
          <FaBath />
          <span>{Math.floor(property.bathrooms)} bath</span>
        </div>
      )}
    </div>
    {property.sq_ft && (
      <div className="sqft-details">
        <FaRulerCombined />
        <span>{property.sq_ft} sqft</span>
      </div>
    )}
  </div>
  {property.next_property_url && (
    <PropertyUrl 
      href={property.next_property_url} 
      target="_blank" 
      rel="noopener noreferrer"
    >
      <FaExternalLinkAlt size={14} />
      Property URL
    </PropertyUrl>
  )}
</PropertyDetails>

              {property.description && (
                <div className="property-description">
                  {isDescriptionExpanded ? (
                    <>
                      <Card.Text>{property.description}</Card.Text>
                      <SeeMoreButton onClick={() => setIsDescriptionExpanded(false)}>
                        See less
                      </SeeMoreButton>
                    </>
                  ) : (
                    <>
                      <TruncatedDescription>{property.description}</TruncatedDescription>
                      <SeeMoreButton onClick={() => setIsDescriptionExpanded(true)}>
                        See more
                      </SeeMoreButton>
                    </>
                  )}
                </div>
              )}

              <div className="d-flex justify-content-between mt-3 mb-3">
                <StyledButton 
                  variant="outline-primary" 
                  size="sm" 
                  onClick={handleShowQRCode}
                >
                  <FaQrcode /> QR Code
                </StyledButton>
                {!property.isEmpty && (
                  <StyledButton 
                    variant="primary"
                    onClick={() => onDocumentDownload(property).catch(handleDownloadError)}
                  >
                    <FaDownload /> Documents
                  </StyledButton>
                )}
              </div>

              {property.owner_details && (
  <AgentInfo>
    <div className="agent-name-section">
      <FaUser style={{ color: 'var(--color-blue-main)' }} />
      <span>Listed by</span>
      <Link 
        to={`/agent/${property.owner_details.id}`}
        style={{ 
          color: 'var(--color-blue-main)', 
          textDecoration: 'none',
          fontWeight: 'bold'
        }}
      >
        {property.owner_details.name || property.owner_details.username}
      </Link>
    </div>
    <div className="agent-details">
      {property.owner_details.profile?.company && (
        <span>{property.owner_details.profile.company}</span>
      )}
      {property.owner_details.profile?.state && (
        <span> • {property.owner_details.profile.state}</span>
      )}
    </div>
  </AgentInfo>
)}

              {isUploadSection && onEdit && onDelete && !property.isEmpty && (
                <div className="mt-2">
                  <StyledButton variant="outline-primary" size="sm" onClick={() => onEdit(property)}>
                    <FaEdit /> Edit
                  </StyledButton>
                  <StyledButton variant="outline-danger" size="sm" className="ms-2" onClick={() => onDelete(property.id)}>
                    <FaTrash /> Delete
                  </StyledButton>
                </div>
              )}

              {property.isEmpty && (
                <Alert variant="info" className="mt-3">
                  This property is not in our database yet. An agent can add details for this property.
                </Alert>
              )}

              {error && (
                <Alert variant="danger" className="mt-2" onClose={() => setError(null)} dismissible>
                  {error}
                </Alert>
              )}
            </Card.Body>
          </Col>
        </Row>
      </StyledCard>

      <QRCodeModal 
        show={showQRCode} 
        onHide={() => {
          setShowQRCode(false);
          setError(null);
          setQRCodeUrl(null);
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Property QR Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <QRCodeDisplay url={qrCodeUrl} />
        </Modal.Body>
        <Modal.Footer>
          <Button 
            variant="secondary" 
            onClick={() => {
              setShowQRCode(false);
              setError(null);
              setQRCodeUrl(null);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </QRCodeModal>

      <PropertyFolders 
    property={property}
    isOpen={showFolderModal}
    onClose={() => setShowFolderModal(false)}
    userProfile={currentUser}
    updateIsSaved={setIsSaved}
  />
    </>
  );
};

export default PropertyCard;