import React, { useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaArrowRight, FaHome, FaFileAlt, FaUsers, FaChartLine, FaCheck } from 'react-icons/fa';
import heroImage from './content-picture.jpeg';
import leftimg from './homeimagelay.avif';
import rightimg from './imagelay.avif';
import Contact from './Contact';
import SubscriptionPopup from './SubscriptionPopup';
import SignInPopup from './SignInPopup';

const Home = () => {
  const [showSubscriptionPopup, setShowSubscriptionPopup] = useState(false);
  const [showSignInPopup, setShowSignInPopup] = useState(false);

  return (
    <div className="hero-section">
      <Container>
        <Row className="justify-content-center">
          <Col md={8}>
            <div className="hero-content">
              <h1>
                EMPOWERING AGENTS WITH<br />
                TOOLS FOR SUCESS <div className="connections-container">
                  <img src={heroImage} alt="Hero" className="hero-image" />
                </div>
              </h1>
            </div>
          </Col>
        </Row>
        <Row className="home-imagelay">
          <Col md={12}>
            <Row className="align-items-center">
              <Col md={6} className="mb-4 mb-md-0">
                <img src={leftimg} alt="Left" className="left-image img-fluid" />
              </Col>
              <Col md={6}>
                <div className="filler-content">
                  <ul>
                    <li>
                      <strong>Broker Compensation Contracts</strong>
                      <p>Compare compensation contracts from various brokerages. Understand commission structures to make informed career decisions.</p>
                    </li>
                    <li>
                      <strong>Network with Professionals</strong>
                      <p>Connect with agents and brokers. Build valuable relationships within the real estate community.</p>
                    </li>
                    <li>
                      <strong>Stay Market Savvy</strong>
                      <p>Stay updated on real estate market trends and compensation benchmarks. Make data-driven decisions to advance your career.</p>
                    </li>
                  </ul>
                  <button className="cta-btn" onClick={() => setShowSignInPopup(true)}>Join Now</button>
                  <button className="cta-arrow-btn"><FaArrowRight /></button>
                </div>

              </Col>
            </Row>
            <div className="blue-circle d-none d-md-flex">
              <div className="inner-circle">
                <FaHome className="home-icon" />
              </div>
            </div>
            <Row className="align-items-center mt-5">
              <Col md={6} className="order-md-2 mb-4 mb-md-0">
                <img src={rightimg} alt="Right" className="right-image img-fluid" />
              </Col>
              <Col md={6} className="order-md-1">
                <div className="right-content">
                  <p>
                    Get complete access to broker compensation details and contracts, all in one convenient location. </p>


                  <Link to="/Properties">
                    <button className="cta-btn">View Properties</button>
                  </Link>
                  <Link to="/Properties">
                    <button className="cta-arrow-btn"><FaArrowRight /></button>
                  </Link>
                </div>
              </Col>

            </Row>
          </Col>
        </Row>
        <Row className="works-section">
          <h2>How it works?</h2>
          <Col md={4}>
            <Card>
              <div className="card-icon-container">
                <FaFileAlt className="card-icon" />
              </div>
              <Card.Body>
                <Card.Title>Access Property Documents</Card.Title>
                <Card.Text>
                  This agent-only page centralizes broker compensation details. Agents can quickly update and find compensation info, allowing more focus on client care and business growth.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card>
              <div className="card-icon-container">
                <FaUsers className="card-icon" />
              </div>
              <Card.Body>
                <Card.Title>Connect with Professionals</Card.Title>
                <Card.Text>
                  Join our community of agents, builders, and real estate experts. Collaborate, share knowledge, and ensure agents know available compensation to best serve clients.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card>
              <div className="card-icon-container">
                <FaChartLine className="card-icon" />
              </div>
              <Card.Body>
                <Card.Title>Stay Market-Informed</Card.Title>
                <Card.Text>
                  Stay updated on broker compensation trends. Inform your clients about how broker compensation benefits both parties. Make data-driven decisions to enhance your career.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>


        <Row className="exist-section">
    <Col xs={12}>
      <h2 className="text-center mb-4">Why We Exist?</h2>
    </Col>
    <Col xs={12} sm={6} className="mb-3">
      <Card className="h-100">
        <Card.Body className="d-flex flex-column align-items-center justify-content-center">
          <div className="circle-number">1</div>
          <Card.Text className="text-center">
            Recent changes in the real estate industry threaten agents and clients due to a lack of foresight and protection for the public.
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
    <Col xs={12} sm={6} className="mb-3">
      <Card className="h-100">
        <Card.Body className="d-flex flex-column align-items-center justify-content-center">
          <div className="circle-number">2</div>
          <Card.Text className="text-center">
            We created a central platform for broker compensation info to prioritize clients' needs and uphold the American dream over corporate interests.
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
    <Col xs={12} sm={6} className="mb-3">
      <Card className="h-100">
        <Card.Body className="d-flex flex-column align-items-center justify-content-center">
          <div className="circle-number">3</div>
          <Card.Text className="text-center">
            Although intended to be free, we charge a fee to cover server upkeep and marketing costs. We hope to reduce this fee as more agents join.
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
    <Col xs={12} sm={6} className="mb-3">
      <Card className="h-100">
        <Card.Body className="d-flex flex-column align-items-center justify-content-center">
          <div className="circle-number">4</div>
          <Card.Text className="text-center">
            We are brokerage-agnostic, not owned by any brokerage, and run by agents like you to protect the business and provide support for fellow agents.
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
  </Row>





        <Row className="pricing-section justify-content-center">
          <Col xs={12}>
            <h2 className="text-center mb-4">Pricing Plans</h2>
          </Col>
          <Col md={5} sm={12} className="mb-4">
            <Card className="pricing-card h-100">
              <Card.Body className="d-flex flex-column">
                <Card.Title className="text-center">Monthly Subscription</Card.Title>
                <Card.Text className="flex-grow-1">
                  <div className="price text-center">$2.99/month</div>
                  <div className="placeholder-text text-center mb-3">Unlock advanced features</div>
                  <Button
                    className="pricing-button w-100 mb-3"
                    onClick={() => setShowSubscriptionPopup(true)}
                  >
                    Subscribe Now
                  </Button>
                  <hr />
                  <ul className="pricing-features">
                    <li><FaCheck className="pricing-check-icon" /> View compensation amounts</li>
                    <li><FaCheck className="pricing-check-icon" /> Download compensation contracts</li>
                    <li><FaCheck className="pricing-check-icon" /> Upload property details</li>
                    <li><FaCheck className="pricing-check-icon" /> Email agents using our template</li>
                    <li><FaCheck className="pricing-check-icon" /> Invite agents to update information</li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={5} sm={12} className="mb-4">
            <Card className="pricing-card h-100">
              <Card.Body className="d-flex flex-column">
                <Card.Title className="text-center">Yearly Subscription</Card.Title>
                <Card.Text className="flex-grow-1">
                  <div className="price text-center">$30.00/year</div>
                  <div className="placeholder-text text-center mb-3">Save 16% with annual billing</div>
                  <Button
                    className="pricing-button w-100 mb-3"
                    onClick={() => setShowSubscriptionPopup(true)}
                  >
                    Subscribe Now
                  </Button>
                  <hr />
                  <ul className="pricing-features">
                    <li><FaCheck className="pricing-check-icon" /> All Monthly features included</li>
                    <li><FaCheck className="pricing-check-icon" /> Convenient once-a-year billing</li>
                    <li><FaCheck className="pricing-check-icon" /> Custom email templates</li>
                  </ul>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Contact />
      {showSignInPopup && <SignInPopup onClose={() => setShowSignInPopup(false)} />}
      {showSubscriptionPopup && (
        <SubscriptionPopup
          onClose={() => setShowSubscriptionPopup(false)}
          onSubscribe={() => {
            setShowSubscriptionPopup(false);
          }}
        />
      )}
    </div>
  );
};

export default Home;