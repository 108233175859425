import React from 'react';
import { NavBar } from './NavBar';

const Layout = ({ children, userProfile, setUserProfile, updateUserProfile }) => {
  return (
    <>
      <NavBar
        userProfile={userProfile}
        setUserProfile={setUserProfile}
        updateUserProfile={updateUserProfile}
      />
      <div>{children}</div>
    </>
  );
};

export default Layout;