import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const MOCK_LATITUDE = 37.7749;
const MOCK_LONGITUDE = -122.4194;

let useMockGeolocation = true;

const getUserLocation = () => {
  return new Promise((resolve) => {
    if (useMockGeolocation) {
      resolve({
        latitude: MOCK_LATITUDE,
        longitude: MOCK_LONGITUDE
      });
    } else if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        position => resolve({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        }),
        () => resolve({
          latitude: MOCK_LATITUDE,
          longitude: MOCK_LONGITUDE
        })
      );
    } else {
      resolve({
        latitude: MOCK_LATITUDE,
        longitude: MOCK_LONGITUDE
      });
    }
  });
};

const addGeolocationHeaders = async (config) => {
  const location = await getUserLocation();
  config.headers['X-User-Latitude'] = location.latitude.toString();
  config.headers['X-User-Longitude'] = location.longitude.toString();
  return config;
};

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Token ${token}`;
    }

    // Set default headers for all requests
    config.headers['Accept'] = 'application/json';

    // Set Content-Type based on the request method and data
    if (config.method === 'post' || config.method === 'put' || config.method === 'patch') {
      // If the data is FormData, don't set Content-Type (browser will set it)
      if (!(config.data instanceof FormData)) {
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
      }
    }

    return await addGeolocationHeaders(config);
  },
  (error) => Promise.reject(error)
);
const objectToFormData = (obj) => {
  const formData = new URLSearchParams();
  Object.entries(obj).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      formData.append(key, value);
    }
  });
  return formData.toString();
};

export const enableMockGeolocation = () => {
  useMockGeolocation = true;
  console.log('Mock geolocation enabled. Using California coordinates.');
};

export const disableMockGeolocation = () => {
  useMockGeolocation = false;
  console.log('Mock geolocation disabled. Using real geolocation.');
};

export const register = async (username, email, password, stateId) => {
  try {
    enableMockGeolocation();

    const registerAxios = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    });

    const location = await getUserLocation();
    const headers = {
      'X-User-Latitude': location.latitude.toString(),
      'X-User-Longitude': location.longitude.toString(),
    };

    const response = await registerAxios.post('/api/register/', {
      username,
      email,
      password,
      profile: { state: stateId }
    }, { headers });

    console.log('Registration response:', response.data);
    disableMockGeolocation();
    return response.data;
  } catch (error) {
    console.error('Registration error in auth.js:', error);
    disableMockGeolocation();
    if (error.response) {
      throw error.response.data;
    } else if (error.request) {
      throw new Error('No response received from the server');
    } else {
      throw new Error('Error setting up the request');
    }
  }
};

export const verifyEmail = async (uidb64, token) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/api/verify-email/${uidb64}/${token}/`;
    const response = await axios.get(url);
    return { 
      success: true, 
      message: response.data.message || 'Email verified successfully. You can now log in.' 
    };
  } catch (error) {
    return { 
      success: false, 
      message: error.response?.data?.error || 'Registration link expired or invalid. Please try again.'
    };
  }
};

export const login = async (username, password) => {
  try {
    await new Promise(resolve => setTimeout(resolve, 500));
    const response = await axiosInstance.post('api/login/', { username, password });
    if (response.data?.token) {
      localStorage.setItem('token', response.data.token);
      
      return response.data;
    }
    throw new Error('Invalid response from server');
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.error || 'Server error');
    } else if (error.request) {
      throw new Error('No response from server');
    }
    throw error;
  }
};

export const fetchUserProfile = async (userId = null) => {
  try {
    const endpoint = userId ? `api/user/profile/${userId}/` : 'api/user/profile/';
    const profileResponse = await axiosInstance.get(endpoint);
    const subscriptionResponse = await axiosInstance.get('api/user/subscription/');
    
    return {
      ...profileResponse.data,
      name: profileResponse.data.name || `${profileResponse.data.first_name || ''} ${profileResponse.data.last_name || ''}`.trim(),
      subscription: subscriptionResponse.data
    };
  } catch (error) {
    throw error;
  }
};

export const fetchAllProperties = async () => {
  try {
    const response = await axiosInstance.get('api/properties/');
    console.log('API response:', response.data);

    if (Array.isArray(response.data)) {
      return { properties: response.data, warning: null };
    } else if (typeof response.data === 'object') {
      const properties = response.data.results || [];
      const warning = response.data.warning || null;
      return { properties, warning };
    }
    return { properties: [], warning: 'Unexpected data format' };
  } catch (error) {
    console.error('Error fetching properties:', error);
    if (error.response?.status === 403) {
      return { properties: [], warning: 'Access denied: Location restrictions apply' };
    }
    throw error;
  }
};

export const fetchPropertyDetails = async (propertyId) => {
  try {
    const response = await axiosInstance.get(`api/property/${propertyId}/`);
    return response.data;
  } catch (error) {
    if (error.response?.status === 403) {
      throw new Error('Access denied: Location restrictions apply');
    }
    throw error;
  }
};

export const createProperty = async (propertyData) => {
  try {
    const response = await axiosInstance.post('api/properties/', propertyData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    return response.data;
  } catch (error) {
    if (error.response?.status === 403) {
      throw new Error('Access denied: Location restrictions apply');
    }
    throw error;
  }
};

export const updateProperty = async (propertyId, formData) => {
  try {
    const response = await axiosInstance.patch(`api/properties/${propertyId}/`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    return response.data;
  } catch (error) {
    if (error.response?.status === 403) {
      throw new Error('Access denied: Location restrictions apply');
    }
    throw error;
  }
};

export const deleteProperty = async (propertyId) => {
  try {
    await axiosInstance.delete(`api/properties/${propertyId}/`);
    return true;
  } catch (error) {
    if (error.response?.status === 403) {
      throw new Error('Access denied: Location restrictions apply');
    }
    throw error;
  }
};

export const fetchPropertyQRCode = async (propertyId) => {
  try {
    console.log('Fetching QR code for property:', propertyId);
    console.log('API URL:', `${process.env.REACT_APP_API_URL}/api/property/${propertyId}/qr-code/`);
    
    // Update the endpoint to match Django's URLconf
    const response = await axiosInstance.get(`api/property/${propertyId}/qr-code/`);
    console.log('QR code API response:', response);
    
    if (response.data) {
      // If the response contains a direct qr_code_url, use it
      if (response.data.qr_code_url) {
        return { qr_code_url: response.data.qr_code_url };
      }
      
      // If we need to construct the URL from the path
      if (response.data.qr_code) {
        const baseUrl = process.env.REACT_APP_API_URL || '';
        const qrCodePath = response.data.qr_code;
        // Ensure we have a clean URL by removing any duplicate slashes
        const qrCodeUrl = `${baseUrl}/media/${qrCodePath}`.replace(/([^:]\/)\/+/g, "$1");
        return { qr_code_url: qrCodeUrl };
      }
    }
    
    throw new Error('QR code URL not found in response');
  } catch (error) {
    console.error('QR code fetch error:', error);
    console.error('Error response:', error.response);
    
    if (error.response?.status === 403) {
      throw new Error('Access denied: Location restrictions apply');
    } else if (error.response?.status === 404) {
      throw new Error('QR code not found for this property');
    }
    throw new Error('Failed to fetch QR code');
  }
};

export const uploadProperty = async (formData, onUploadProgress) => {
  try {
    const response = await axiosInstance.post('api/properties/', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        if (onUploadProgress) {
          onUploadProgress(percentCompleted);
        }
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading property:', error);
    throw error;
  }
};

export const updateUserProfile = async (updatedFields) => {
  try {
    const dataToUpdate = {
      name: updatedFields.name,
      profile: {}
    };

    if (updatedFields.phone) dataToUpdate.profile.phone = updatedFields.phone;
    if (updatedFields.company) dataToUpdate.profile.company = updatedFields.company;
    if (updatedFields.state) dataToUpdate.profile.state = updatedFields.state;
    if (updatedFields.state_id) dataToUpdate.profile.state_id = updatedFields.state_id;
    if (updatedFields.business_address) dataToUpdate.profile.business_address = updatedFields.business_address;

    const response = await axiosInstance.put('api/user/profile/update/', dataToUpdate);

    return {
      user: {
        ...response.data.user,
        name: response.data.user.name || updatedFields.name,
        profile: { ...response.data.user.profile }
      }
    };
  } catch (error) {
    throw error;
  }
};

export const fetchUploads = async () => {
  try {
    const response = await axiosInstance.get('api/user/uploads/');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const activateSubscription = async (paymentMethodId, profileData) => {
  try {
    const response = await axiosInstance.post('api/subscribe/', {
      paymentMethodId,
      ...profileData
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchBillingInfo = async () => {
  try {
    const response = await axiosInstance.get('api/user/billing/');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateBillingInfo = async (data) => {
  try {
    const response = await axiosInstance.put('api/user/billing/', data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const property_search = async (query) => {
  try {
    const response = await axiosInstance.get(`api/property-search/?q=${encodeURIComponent(query)}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const changePassword = async (oldPassword, newPassword, confirmPassword) => {
  try {
    const response = await axiosInstance.post('api/user/update-password/', {
      old_password: oldPassword,
      new_password: newPassword,
      confirm_password: confirmPassword
    });
    return response.data;
  } catch (error) {
    if (error.response?.data) {
      throw new Error(error.response.data.error || 'An error occurred while changing the password.');
    }
    throw new Error('An unexpected error occurred.');
  }
};

export const requestPasswordReset = async (email) => {
  try {
    const response = await axiosInstance.post('api/password-reset/', { email });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (uidb64, token, newPassword) => {
  try {
    const response = await axiosInstance.post(`api/password-reset-confirm/${uidb64}/${token}/`, {
      new_password: newPassword
    });
    return response.data;
  } catch (error) {
    if (error.response?.data) {
      throw new Error(error.response.data.error || 'An error occurred while resetting the password.');
    }
    throw new Error('An unexpected error occurred.');
  }
};
export const getUserDetails = async (userId) => {
  try {
    
    const response = await axiosInstance.get(`api/user/profile/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching user details:', error);
    throw new Error('Failed to fetch user details. Please try again later.');
  }
};

// Basic Folder Operations

// 1. List All Folders
export const getFolders = async () => {
  try {
    const response = await axiosInstance.get('/api/folders/');
    return response.data;
  } catch (error) {
    console.error('Error fetching folders:', error);
    throw error?.response?.data?.error || error.message || 'Failed to fetch folders';
  }
};

// 2. Create New Folder
export const createFolder = async (name, description = '') => {
  try {
    const data = {
      name: name.trim(),
      description: description?.trim() || ''
    };

    const response = await axiosInstance.post('/api/folders/', data, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error creating folder:', error);
    throw error?.response?.data?.error || error.message || 'Failed to create folder';
  }
};

// 3. Get Single Folder Details
export const getFolderDetails = async (folderId) => {
  try {
    const response = await axiosInstance.get(`api/folders/${folderId}/`);
    return response.data;
  } catch (error) {
    throw handleApiError(error, 'Error fetching folder details:');
  }
};

// 4. Update Folder
export const updateFolder = async (folderId, { name, description }) => {
  try {
    const data = {
      name: name.trim(),
      description: description?.trim() || ''
    };

    const response = await axiosInstance.put(
      `/api/folders/${folderId}/`,
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error updating folder:', error);
    throw error?.response?.data?.error || error.message || 'Failed to update folder';
  }
};


// 5. Delete Folder
export const deleteFolder = async (folderId) => {
  try {
    await axiosInstance.delete(`api/folders/${folderId}/`);
    return true;
  } catch (error) {
    throw handleApiError(error, 'Error deleting folder:');
  }
};

// 6. Get Folder Summary
export const getFolderSummary = async () => {
  try {
    const response = await axiosInstance.get('api/folders/summary/');
    return response.data;
  } catch (error) {
    throw handleApiError(error, 'Error fetching folder summary:');
  }
};

// 8. Get Properties in a Folder
export const getFolderProperties = async (folderId) => {
  try {
    const response = await axiosInstance.get(`/api/folders/${folderId}/properties/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching folder properties:', error);
    throw error?.response?.data?.error || error.message || 'Failed to fetch folder properties';
  }
};

// 9. Save Property to Folder
export const savePropertyToFolder = async (propertyId, options = {}) => {
  try {
    if (!propertyId) {
      throw new Error('Property ID is required');
    }
    
    if (!options.folder_id) {
      throw new Error('Folder ID is required');
    }

    const data = {
      folder_id: Number(options.folder_id),
      notes: options.notes?.trim() || ''
    };

    const response = await axiosInstance.post(
      `/api/properties/${propertyId}/save-to-folder/`,
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    return response.data;
  } catch (error) {
    if (error.response?.data?.message) {
      throw new Error(error.response.data.message);
    }
    throw error;
  }
};

// 10. Remove Property from Folder
export const removePropertyFromFolder = async (folderId, propertyId) => {
  try {
    await axiosInstance.delete(`/api/folders/${folderId}/properties/${propertyId}/`);
    return true;
  } catch (error) {
    console.error('Error removing property from folder:', error);
    throw error?.response?.data?.error || error.message || 'Failed to remove property from folder';
  }
};

// 11. Update Property Notes in Folder
export const updatePropertyNotesInFolder = async (folderId, propertyId, notes) => {
  try {
    const formData = objectToFormData({ notes });

    const response = await axiosInstance.put(
      `api/folders/${folderId}/properties/${propertyId}/update/`,
      formData
    );
    return response.data;
  } catch (error) {
    throw handleApiError(error, 'Error updating property notes:');
  }
};


// 12. Move Property Between Folders
export const movePropertyBetweenFolders = async (propertyId, sourceFolderId, targetFolderId) => {
  try {
    const data = {
      source_folder_id: sourceFolderId,
      target_folder_id: targetFolderId
    };

    const response = await axiosInstance.post(
      `api/properties/${propertyId}/move/`,
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error moving property between folders:', error);
    throw error?.response?.data?.error || error.message || 'Failed to move property between folders';
  }
};

// 13. Get Folders Where Property is Saved
export const getFoldersContainingProperty = async (propertyId) => {
  try {
    const response = await axiosInstance.get(`api/properties/${propertyId}/saved-folders/`);
    return response.data;
  } catch (error) {
    throw handleApiError(error, 'Error fetching property folders:');
  }
};

// Helper function for error handling
const handleApiError = (error, customMessage) => {
  console.error(customMessage, error);
  if (error.response?.data?.error) {
    throw new Error(error.response.data.error);
  }
  throw error;
};

export const logout = () => {
  localStorage.removeItem('token');
};