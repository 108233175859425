import React, { useState, useLayoutEffect, useRef, useEffect } from "react";
import { Navbar, Nav, Container, Dropdown } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import SignUpPopup from './SignUpPopup.js';
import SignInPopup from './SignInPopup.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SubscriptionPopup from "./SubscriptionPopup.js";
import { logout, fetchUserProfile } from '../utils/auth';
import { FaUserCircle } from 'react-icons/fa';

export const NavBar = ({ userProfile, setUserProfile, updateUserProfile }) => {
  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);
  const [showSignUpPopup, setShowSignUpPopup] = useState(false);
  const [showSignInPopup, setShowSignInPopup] = useState(false);
  const [showSubscriptionPopup, setShowSubscriptionPopup] = useState(false);
  const [isAtTop, setIsAtTop] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const navbarRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [displayName, setDisplayName] = useState('');

  useEffect(() => {
    if (userProfile && userProfile.username) {
      setDisplayName(userProfile.username);
    } else {
      setDisplayName('');
    }
  }, [userProfile]);

  const setHeroSectionPadding = () => {
    const heroSection = document.querySelector('.hero-section');
    if (heroSection && navbarRef.current) {
      const navbarHeight = navbarRef.current.clientHeight;
      heroSection.style.paddingTop = `${navbarHeight}px`;
    }
  };

  useEffect(() => {
    setHeroSectionPadding();
    window.addEventListener('resize', setHeroSectionPadding);
    return () => {
      window.removeEventListener('resize', setHeroSectionPadding);
    };
  }, []);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
        setIsAtTop(false);
      } else {
        setScrolled(false);
        setIsAtTop(true);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleSignIn = async (signedInUser) => {
    await updateUserProfile();
    setShowSignInPopup(false);
    navigate('/properties');
  };

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

  const handleCloseSignUpPopup = () => {
    setShowSignUpPopup(false);
  }

  const handleCloseSignInPopup = () => {
    setShowSignInPopup(false);
  }

  const handleSignUpClick = () => {
    setShowSignInPopup(false);
    setShowSignUpPopup(true);
  };

  const handleSignInClick = () => {
    setShowSignUpPopup(false);
    setShowSignInPopup(true);
  };

  const handleSubscribeClick = () => {
    setShowSignInPopup(false);
    setShowSignUpPopup(false);
    setShowSubscriptionPopup(true);
  };

  const handleSignUpSuccess = async (userData) => {
    setShowSignUpPopup(false);
    toast.success('Registration successful. Please check your email to verify your account.');
  };
  useLayoutEffect(() => {
    setHeroSectionPadding();
    window.addEventListener('resize', setHeroSectionPadding);
    return () => {
      window.removeEventListener('resize', setHeroSectionPadding);
    };
  }, []);

  useEffect(() => {
    const path = location.pathname.toLowerCase();
    if (path === "/") {
      setActiveLink("home");
    } else if (path === "/properties") {
      setActiveLink("properties");
    } else if (path === "/agents" || path.startsWith("/agent/")) {
      setActiveLink("agents");
    } else if (path === "/contact") {
      setActiveLink("contact");
    }
  }, [location]);

  const handleLogout = () => {
    logout();
    setUserProfile(null);
    navigate('/');
  };

  const closeNavbar = () => {
    setExpanded(false);
  };

  return (
    <Navbar
    ref={navbarRef}
    expand="md"
    className={`
      ${scrolled ? "scrolled" : ""} 
      ${activeLink === 'home' ? 'home' : ''} 
      ${activeLink === 'agents' ? 'agents' : ''} 
      ${activeLink === 'properties' && isAtTop ? 'properties-top' : ''}
    `}
    expanded={expanded}
    onToggle={() => setExpanded(!expanded)}
  >
      <Container>
        <Navbar.Brand as={Link} to="/" className={`${scrolled ? "scrolled" : ""} ${activeLink === 'home' ? 'home' : ''}`}>
          Agent Spyglass
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto">
        <Nav.Link 
          as={Link} 
          to="/" 
          className={`navbar-link ${activeLink === 'home' ? 'active' : ''}`} 
          onClick={() => { onUpdateActiveLink('home'); closeNavbar(); }}
        >
          About
        </Nav.Link>
        <Nav.Link 
          as={Link} 
          to="/properties" 
          className={`navbar-link ${activeLink === 'properties' ? 'active' : ''}`} 
          onClick={() => { onUpdateActiveLink('properties'); closeNavbar(); }}
        >
          Properties
        </Nav.Link>
        <Nav.Link
          as={Link}
          to="/agents"
          className={`navbar-link ${activeLink === 'agents' ? 'active' : ''}`}
          onClick={() => { onUpdateActiveLink('agents'); closeNavbar(); }}
        >
          Agents
        </Nav.Link>
        <HashLink
          smooth
          to="/#contact"
          className={`nav-link navbar-link ${activeLink === 'contact' ? 'active' : ''}`}
          onClick={() => { onUpdateActiveLink('contact'); closeNavbar(); }}
        >
          Contact
        </HashLink>
      </Nav>
          <span className="navbar-text">
            {userProfile ? (
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic" className={`profile-btn ${activeLink === 'home' ? 'home' : ''}`}>
                  <FaUserCircle className="me-2" />
                  {displayName}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to="/profile">Profile</Dropdown.Item>
                  <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <>
                <button className={`cta ${activeLink === 'home' ? 'home' : ''}`} onClick={handleSignInClick}>
                  <span>Sign In</span>
                </button>
                <button className={`cta ${activeLink === 'home' ? 'home' : ''}`} onClick={handleSignUpClick}>
                  <span>Sign Up</span>
                </button>
              </>
            )}
          </span>
        </Navbar.Collapse>
      </Container>
      {showSignUpPopup && (
        <SignUpPopup
          onClose={handleCloseSignUpPopup}
          onSignInClick={handleSignInClick}
          onSignUpSuccess={handleSignUpSuccess}
        />
      )}
      {showSignInPopup && (
        <SignInPopup
          onClose={handleCloseSignInPopup}
          onSignUpClick={handleSignUpClick}
          onSubscribeClick={handleSubscribeClick}
          onSignIn={handleSignIn}
        />
      )}
      {showSubscriptionPopup && (
        <SubscriptionPopup onClose={() => setShowSubscriptionPopup(false)} />
      )}
    </Navbar>
  );
};

export default NavBar;