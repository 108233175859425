import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { getFolders, getFolderProperties } from '../../utils/auth';

const SavedPropertiesContext = createContext();


export const SavedPropertiesProvider = ({ children }) => {
  const [savedPropertyIds, setSavedPropertyIds] = useState(new Set());
  const [isLoading, setIsLoading] = useState(true);

  const loadSavedProperties = useCallback(async () => {
    try {
      const folders = await getFolders();
      const savedIds = new Set();

      await Promise.all(
        folders.map(async (folder) => {
          try {
            const properties = await getFolderProperties(folder.id);
            properties.forEach((prop) => {
              const propertyId = prop.property_details?.id || prop.id;
              if (propertyId) {
                savedIds.add(propertyId);
              }
            });
          } catch (error) {
            console.error(`Error loading properties for folder ${folder.id}:`, error);
          }
        })
      );

      setSavedPropertyIds(savedIds);
    } catch (error) {
      console.error('Error loading saved properties:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    loadSavedProperties();
  }, [loadSavedProperties]);

  const value = {
    savedPropertyIds,
    isLoading,
    refreshSavedProperties: loadSavedProperties
  };

  return (
    <SavedPropertiesContext.Provider value={value}>
      {children}
    </SavedPropertiesContext.Provider>
  );
};

// Custom hook to use saved properties context
export const useSavedProperties = () => {
  const context = useContext(SavedPropertiesContext);
  if (!context) {
    throw new Error('useSavedProperties must be used within a SavedPropertiesProvider');
  }
  return context;
};

export const usePropertySavedState = (propertyId, currentUser) => {
  const { savedPropertyIds, isLoading, refreshSavedProperties } = useSavedProperties();
  const [isSaved, setIsSaved] = useState(false);

  useEffect(() => {
    if (!isLoading && propertyId) {
      setIsSaved(savedPropertyIds.has(propertyId));
    }
  }, [propertyId, savedPropertyIds, isLoading]);

  const updateSavedState = useCallback(async (newState) => {
    setIsSaved(newState);
    await refreshSavedProperties();
  }, [refreshSavedProperties]);

  return [isSaved, updateSavedState];
};