import React, { useState, useEffect } from 'react';
import { usePropertySavedState } from './hooks/usePropertySavedState';
import PropertyCard from './PropertyCard';
import { getFolderProperties, fetchPropertyDetails } from '../utils/auth';
import { Card, Spinner, Alert } from 'react-bootstrap';
import { ChevronDown, ChevronUp } from 'lucide-react';

const PropertyCardWrapper = ({
  property,
  viewMode,
  isSubscribed,
  onEmailClick,
  onDocumentDownload,
  onEdit,
  onDelete,
  currentUser,
  folderId,
  onRemoveFromFolder,
  onMoveProperty
}) => {
  const [propertyDetails, setPropertyDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSaved, setIsSaved] = usePropertySavedState(property.id, currentUser);
  const [showFullNotes, setShowFullNotes] = useState(false);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        let details;

        if (folderId) {
          // Get folder-specific properties
          const folderProperties = await getFolderProperties(folderId);
          const foundProperty = folderProperties.find(p => 
            p.id === property.id || p.property_details?.id === property.id
          );

          if (foundProperty) {
            // Get full property details
            const propertyId = foundProperty.property_details?.id || foundProperty.id;
            details = await fetchPropertyDetails(propertyId);

            // Merge folder-specific data
            details = {
              ...details,
              folder_notes: foundProperty.notes,
              folder_added_date: foundProperty.added_date,
              in_folder: true
            };
          }
        } else {
          details = await fetchPropertyDetails(property.id);
        }

        if (details) {
          setPropertyDetails(details);
          setIsSaved(true);
        } else {
          setError('Property not found');
        }
      } catch (err) {
        console.error('Error fetching property details:', err);
        setError(err.message || 'Error loading property details');
      } finally {
        setLoading(false);
      }
    };

    if (property?.id) {
      fetchDetails();
    } else {
      setPropertyDetails(property);
      setLoading(false);
    }
  }, [property.id, folderId, setIsSaved]);

  const renderNotes = () => {
    if (!propertyDetails?.folder_notes) return null;

    const notes = propertyDetails.folder_notes;
    const isLongNotes = notes.length > 150;
    const displayNotes = showFullNotes ? notes : notes.slice(0, 150);

    return (
      <Card className="mt-3 bg-light">
        <Card.Body>
          <h6 className="mb-2">Notes</h6>
          <p className="text-muted mb-1" style={{ fontSize: '0.9rem' }}>
            {displayNotes}
            {isLongNotes && !showFullNotes && '...'}
          </p>
          {isLongNotes && (
            <button
              onClick={() => setShowFullNotes(!showFullNotes)}
              className="btn btn-link btn-sm p-0 text-primary"
              style={{ fontSize: '0.85rem' }}
            >
              {showFullNotes ? (
                <span className="d-flex align-items-center gap-1">
                  Show less <ChevronUp size={14} />
                </span>
              ) : (
                <span className="d-flex align-items-center gap-1">
                  Show more <ChevronDown size={14} />
                </span>
              )}
            </button>
          )}
        </Card.Body>
      </Card>
    );
  };

  const renderFolderActions = () => {
    if (!propertyDetails?.in_folder) return null;

    return (
      <div className="mt-3 d-flex gap-2">
        <button
          onClick={() => onMoveProperty?.(propertyDetails)}
          className="btn btn-outline-primary btn-sm"
        >
          Move to folder
        </button>
        <button
          onClick={() => onRemoveFromFolder?.(propertyDetails)}
          className="btn btn-outline-danger btn-sm"
        >
          Remove from folder
        </button>
      </div>
    );
  };

  if (loading) {
    return (
      <Card className="p-4">
        <div className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" variant="primary" size="sm" />
          <span className="ms-2 text-muted">Loading property details...</span>
        </div>
      </Card>
    );
  }

  if (error) {
    return (
      <Alert variant="danger">
        {error}
      </Alert>
    );
  }

  if (!propertyDetails) {
    return null;
  }

  return (
    <div className="property-card-container">
      <PropertyCard
        property={propertyDetails}
        viewMode={viewMode}
        isSubscribed={isSubscribed}
        onEmailClick={onEmailClick}
        onDocumentDownload={onDocumentDownload}
        onEdit={onEdit}
        onDelete={onDelete}
        isSaved={isSaved}
        setIsSaved={setIsSaved}
        currentUser={currentUser}
      />
      {renderFolderActions()}
    </div>
  );
};

export default PropertyCardWrapper;