import React, { useState, useRef, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import { updateUserProfile, activateSubscription } from '../utils/auth';
import PaymentForm from './Payment';
import SubscriptionResult from './SubscriptionResult';
import styled from 'styled-components';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLISHABLE_KEY } from '../config';
import { X } from 'lucide-react';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const StyledButton = styled.button`
  background-color: var(--color-blue-main);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;

  &:hover {
    opacity: 0.9;
  }
`;

const AutocompleteWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const AddressInput = styled.input`
  width: 100%;
  padding: 10px;
  padding-right: 30px;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid var(--color-blue-main);
  &:focus {
    outline: none;
    border-bottom: 2px solid var(--color-blue-main);
  }
`;

const ResetButton = styled.button`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: #888;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    color: #333;
  }
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 1px solid var(--color-blue-main);
  font-size: 16px;
  &:focus {
    outline: none;
    border-bottom: 2px solid var(--color-blue-main);
  }
`;

const SuggestionsList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: relative;
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1;
  max-height: 200px;
  overflow-y: auto;
`;

const SuggestionItem = styled.li`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const SubscriptionPopup = ({ onClose, onSubscribe, userProfile }) => {
  const [name, setName] = useState(userProfile?.name || '');
  const [email] = useState(userProfile?.email || '');
  const [phone, setPhone] = useState(userProfile?.phone || '');
  const [company, setCompany] = useState(userProfile?.company || '');
  const [stateId, setStateId] = useState(userProfile?.state_id || '');
  const [businessAddress, setBusinessAddress] = useState(userProfile?.business_address || '');
  const [suggestions, setSuggestions] = useState([]);
  const [showPayment, setShowPayment] = useState(false);
  const [error, setError] = useState('');
  const [showResult, setShowResult] = useState(false);
  const [activationSuccess, setActivationSuccess] = useState(false);
  const [resultMessage, setResultMessage] = useState('');

  const addressInputRef = useRef(null);
  const autocompleteService = useRef(null);
  const placesService = useRef(null);

  useEffect(() => {
    if (window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
      placesService.current = new window.google.maps.places.PlacesService(document.createElement('div'));
    }
  }, []);

  const handleAddressChange = (e) => {
    const value = e.target.value;
    setBusinessAddress(value);

    if (value.length > 2 && autocompleteService.current) {
      autocompleteService.current.getPlacePredictions(
        { input: value, componentRestrictions: { country: 'us' } },
        (predictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
            setSuggestions(predictions);
          } else {
            setSuggestions([]);
          }
        }
      );
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionSelect = (placeId) => {
    if (placesService.current) {
      placesService.current.getDetails({ placeId }, (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          setBusinessAddress(place.formatted_address);
          setSuggestions([]);
        }
      });
    }
  };

  const resetAddress = () => {
    setBusinessAddress('');
    setSuggestions([]);
    if (addressInputRef.current) {
      addressInputRef.current.focus();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedProfileData = {
        name,
        phone,
        company,
        state: userProfile.profile.state,
        state_id: stateId,
        business_address: businessAddress
      };
      await updateUserProfile(updatedProfileData);
      setShowPayment(true);
    } catch (error) {
      console.error('Error updating profile:', error);
      setError('Failed to update profile. Please try again.');
    }
  };

  const handlePaymentSuccess = async (paymentMethodId) => {
    try {
      const subscriptionData = {
        name,
        email,
        phone,
        company,
        state: userProfile.profile.state,  
        state_id: stateId,
        business_address: businessAddress
      };
    
      const result = await activateSubscription(paymentMethodId, subscriptionData);
  
      if (result.message === 'Subscription activated successfully') {
        const updatedProfile = {
          ...userProfile,
          name: result.user.name || name,
          subscription: result.subscription,
          profile: {
            ...userProfile.profile,
            ...result.profile
          }
        };
        onSubscribe(updatedProfile);
        setActivationSuccess(true);
        setResultMessage('Subscription activated successfully');
      } else {
        throw new Error(result.message || 'Failed to activate subscription');
      }
    } catch (error) {
      console.error('Error activating subscription:', error);
      setActivationSuccess(false);
      setResultMessage('Failed to make successful payment');
    }
    setShowResult(true);
  };

  const handleResultClose = () => {
    setShowResult(false);
    if (activationSuccess) {
      onClose();
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup-container">
        {showResult ? (
          <SubscriptionResult
            success={activationSuccess}
            message={resultMessage}
            onClose={handleResultClose}
          />
        ) : (
          <>
            <div className="popup-content">
              <h2><span>Subscribe Now</span></h2>
              {error && <p className="error-message">{error}</p>}
              {!showPayment ? (
                <>
                  <p>Complete Your Agent Profile to Subscribe</p>
                  <form onSubmit={handleSubmit} className="form-elements">
                    <StyledInput
                      type="text"
                      placeholder="Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                    <StyledInput
                      type="email"
                      placeholder="Email"
                      value={email}
                      readOnly
                    />
                    <StyledInput
                      type="tel"
                      placeholder="Phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      required
                    />
                    <StyledInput
                      type="text"
                      placeholder="Company"
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                      required
                    />
                    <StyledInput
                      type="text"
                      placeholder="State ID"
                      value={stateId}
                      onChange={(e) => setStateId(e.target.value)}
                      required
                    />
                    <AutocompleteWrapper>
                      <AddressInput
                        ref={addressInputRef}
                        type="text"
                        placeholder="Business Address"
                        value={businessAddress}
                        onChange={handleAddressChange}
                        required
                      />
                      {businessAddress && (
                        <ResetButton onClick={resetAddress} type="button">
                          <X size={16} />
                        </ResetButton>
                      )}
                    </AutocompleteWrapper>
                    {suggestions.length > 0 && (
                      <SuggestionsList>
                        {suggestions.map((suggestion) => (
                          <SuggestionItem
                            key={suggestion.place_id}
                            onClick={() => handleSuggestionSelect(suggestion.place_id)}
                          >
                            {suggestion.description}
                          </SuggestionItem>
                        ))}
                      </SuggestionsList>
                    )}
                    <StyledButton type="submit">Continue to Payment</StyledButton>
                  </form>
                </>
              ) : (
                <Elements stripe={stripePromise}>
                  <PaymentForm onPaymentSuccess={handlePaymentSuccess} />
                </Elements>
              )}
            </div>
            <div className="popup-right">
              <button className="close-button" onClick={onClose}>
                <FaTimes />
              </button>
              <div className="popup-right-content">
                <p className="filler-text">Subscribe to upload and view property details!</p>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SubscriptionPopup;