import React, { useState } from 'react';
import { FaTimes, FaEye, FaEyeSlash, FaCheck, FaTimes as FaCross, FaChevronDown } from 'react-icons/fa';
import { register } from '../utils/auth';
import usStates from '../utils/usStates';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SignUpPopup = ({ onClose, onSignInClick, onSignUpSuccess }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [stateId, setStateId] = useState('');
    const [username, setUsername] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [passwordFocused, setPasswordFocused] = useState(false);
    const [isVerificationSent, setIsVerificationSent] = useState(false);
    const [stateInput, setStateInput] = useState('');
    const [showStateDropdown, setShowStateDropdown] = useState(false);
    const [agreedToTerms, setAgreedToTerms] = useState(false);
    const [showTermsModal, setShowTermsModal] = useState(false);
    const [activeDocument, setActiveDocument] = useState('privacy');
    const [passwordRequirements, setPasswordRequirements] = useState({
        length: false,
        uppercase: false,
        lowercase: false,
        number: false,
        special: false
    });
    const [filteredStates, setFilteredStates] = useState(usStates);

    const validatePassword = (password) => {
        const requirements = {
            length: password.length >= 8,
            uppercase: /[A-Z]/.test(password),
            lowercase: /[a-z]/.test(password),
            number: /[0-9]/.test(password),
            special: /[@$!%*?&]/.test(password)
        };
        setPasswordRequirements(requirements);
        return Object.values(requirements).every(Boolean);
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        validatePassword(newPassword);
    };

    const handleStateChange = (e) => {
        const value = e.target.value;
        setStateInput(value);
        setStateId('');
        filterStates(value);
        setShowStateDropdown(true);
    };

    const filterStates = (value) => {
        const filtered = usStates.filter(state =>
            state.name.toLowerCase().includes(value.toLowerCase()) ||
            state.abbreviation.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredStates(filtered);
    };

    const selectState = (state) => {
        setStateId(state.name);
        setStateInput(state.name);
        setShowStateDropdown(false);
    };

    const resetState = () => {
        setStateId('');
        setStateInput('');
        setFilteredStates(usStates);
    };

    const handleSignUp = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');


        if (!agreedToTerms) {
            const errorMessage = 'Please accept the terms and conditions before creating an account.';
            console.log("Terms not agreed, showing error:", errorMessage);
            setError(errorMessage);
            toast.error(errorMessage);
            setIsLoading(false);
            return;
        }

        if (!validatePassword(password)) {
            toast.error('Please ensure all password requirements are met.');
            setIsLoading(false);
            return;
        }

        if (!stateId) {
            toast.error('Please select a state from the dropdown.');
            setIsLoading(false);
            return;
        }

        try {
            const data = await register(username, email, password, stateId);
            setIsVerificationSent(true);
            toast.success('Registration successful. Please check your email to verify your account.');
        } catch (error) {
            console.error('Registration error:', error);

            if (error.response && error.response.data) {
                const errorData = error.response.data;
                if (typeof errorData === 'object') {
                    const errorMessage = Object.values(errorData)[0];
                    if (Array.isArray(errorMessage)) {
                        toast.error(errorMessage[0]);
                    } else {
                        toast.error(errorMessage);
                    }
                } else {
                    toast.error(errorData);
                }
            } else if (error.message) {
                toast.error(error.message);
            } else {
                toast.error('An unexpected error occurred. Please try again.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleSignInClick = () => {
        onClose();
        onSignInClick();
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleTermsToggle = () => {
        setShowTermsModal(!showTermsModal);
    };

    const handleTermsClick = (e) => {
        e.preventDefault();
        setShowTermsModal(true);
    };

    const handleTermsAccept = () => {
        setAgreedToTerms(true);
        setShowTermsModal(false);
        toast.success('Terms and conditions accepted.');
    };

    const handleTermsDecline = () => {
        setAgreedToTerms(false);
        setShowTermsModal(false);
        toast.info('You must accept the terms and conditions to create an account.');
    };

    const handleDocumentChange = (document) => {
        setActiveDocument(document);
    };


    const privacyPolicy = `
1. The Agentspyglass.com ("We," "Us," or "Our") website (the "Site" or "Services") offers information regarding our services. You are trusting us with your data, and we want to make sure it's safe, secure, and handled in a responsible manner. In this Privacy Notice (the "Notice") We commit to protecting your data and outline what data we collect and how it is used. We reserve the right to update this Notice and will post any changes here. We comply with applicable data protection legislation. This Notice states how We collect, use, retain, and disclose information for Site visitors.

   This Privacy Notice is effective as of Jan 1, 2024.

2. Collection and Use of Information
   a. We collect data about you directly from you and automatically through your use of our Site. We may combine data automatically collected with other data that we have collected about you, including from publicly available sources. We encourage you to provide only that data that is necessary for using our Site.
   b. Data collected automatically: Like many websites, we use technologies to receive and store certain types of data when you interact with us through your device. These technologies help us customize your experience with our Site and improve your experience.

3. Sharing Your Data
   a. We do not sell your data to any third parties or disclose it in exchange for money or other valuable consideration. We do not share your data with others except as indicated within this Policy, or when we inform you at a subsequent time.
   b. We may share your data as follows:
      1. With third-party service providers, contractors, or government entities.
      2. To process payment card transactions, sending emails, hosting, IT services, or customer service.
      3. For search engine optimization ("SEO") purposes and for serving you the most relevant advertising.
      4. With service providers and government entities for legal, security, and safety purposes, including sharing data to enforce policies or contracts, address security breaches, and assist in the investigation of fraud, security issues, or other concerns.
      5. If we believe that disclosure is reasonably necessary to comply with a law, regulation, legal, or governmental request; to enforce applicable Terms of Service or this Policy.
      6. For our own internal business purposes including but not limited to product improvement in aggregated, anonymized, and/or de-identified form that cannot reasonably be used to identify you.

4. Categories of Data
   When using the Site, we collect information including but not limited to the following potentially personal data:
   a. Email: This is used as your account login and we may occasionally send emails communicating with you. We will not share this with any third party that's not necessary for the product to function (e.g., our authentication provider).
   b. Entries and Conversations: Data you input into the Service is stored and used in the Service.
   c. Phone Number: If you optionally set up text message reminders or other phone functionality, your phone number will be stored. We will not share your phone number with any third party that is not necessary for the product to function.
   d. Additional information such as browser metadata and product usage analytics to help us understand high-level usage of the product.
   e. Other Identification Data: name, address, identification tax number or similar, economic, financial, or banking data, if applicable (such as billing information).
   f. Any other information you choose to submit to the Service.

5. How We Use Your Data
   We may use data we collect about you to:
   a. Provide our Service to you; use the payment data you provide to alert you, present billing history, and perform internal financial processes.
   b. Improve our offerings through analysis of your use of, or interest in, our offerings.
   c. Send service emails to ensure the Service is working properly.
   d. Subject to your preference, send emails about new features, offerings, or other news about Us or on topics we think would be relevant to you.
   e. Inform you about changes in your services, our service offering, and other important service-related notices.
   f. Communicate information related to the Service, news, and value-added improvements, as well as related activities and training.
   g. Detect, investigate, and prevent potentially illegal, unlawful, or harmful activities and to enforce our policies.
   h. Comply with applicable legal obligations and protect our rights and interests, as well as the rights and interests of our users.
   i. Send special offers and promotional notices via mail or e-mail to customers and prospects who have expressed interest. You may unsubscribe or 'opt-out' of such offers and notifications by following the unsubscribe link on the specific offer received or by contacting us.

6. Security of Your Data
   a. We take reasonable and appropriate steps to protect the data entrusted to us and treat it securely. We implement safeguards designed to protect your data from accidental/unlawful destruction, loss, alteration, and unauthorized dissemination. We will retain your data as needed to fulfill the purposes for which it was collected or as needed to fulfill our business needs or legal requirements. In the event of a security issue, we will disclose relevant information via email or our social media channels.
   b. We have implemented technical and organizational measures designed to protect the data and ensure its security, availability, integrity, and confidentiality.
   c. Data collection is limited to the purposes of processing (or the data that You choose to provide).

7. Use of Third-Party Services
   a. The use of third-party services and software libraries is necessary to help power our features, including providers where raw entries and conversations may be sent to power functionality. By using our Services, you are effectively agreeing to this Privacy Statement of our providers. We will do our best to remove any identifiable information before sending it to our providers, but because we do not control the content that is input, we cannot fully guarantee that identifiable information is not sent to our providers.

8. Privacy Rights
   a. The following disclosures provide additional information about the categories of personal data we collect, the source of the personal data, how we use each category of personal data, and how we disclose personal data. These disclosures do not limit our ability to use or disclose information as described above.
   b. Categories of personal data: Name, email or user ID, profile password, gender, phone number, payment details, date of birth, geolocation, and any other conversation content that might be generated by you on the Service or your use of the Service.
   c. Sources of personal data: Directly from you, third-party sites or sources such as our trusted business partners, analytics tools, and social networks.
   d. Use of personal data: Deliver the Service to you, provide you with information and communicate with you about your account and usage, provide you promotions, enhance and personalize your user experience, improve our Service, and build new services.
   e. Disclosure of personal data: Our affiliates, our service providers for hosting the Service, processing payments, and communicating with you.
   f. To the extent provided for by law, you may have the following privacy rights:
      1. The right to know what personal data we have collected and how we have used and disclosed it.
      2. The right to request the deletion of your personal data.
      3. The right to be free from discrimination relating to the exercise of your privacy rights.
   g. Exercising Your Rights: Data subjects can exercise the above privacy rights by emailing us at EMAIL.
   h. Verification: To protect your personal data from unauthorized access or deletion, we may require you to verify your identity before you can submit a privacy request to know or delete personal data. To keep your data accurate and complete, you can log in to review and update your Account data.
   i. You may also contact us to request the personal data we have collected from you or request the rectification or deletion of such data. We will do our best to honor your requests subject to any legal and contractual obligations. If you do not wish to receive promotional emails from us, you may opt out at any time by using the link in the email itself. Please note that it may take up to thirty (30) days to process your request.
   j. Authorized Agent: You may submit a request to know or delete your personal data through an authorized agent. The agent must present signed written permission to act on your behalf, and you may also be required to independently verify your identity and submit proof of your residency.

9. Cookies
   a. Our Service uses cookies and other similar device identification technologies (collectively "cookies") to provide our services, understand how they are used, and for analytics, personalization, customization, and marketing purposes. Personal data, such as your email address, may be collected through the cookies.
   b. Cookies may also be set by the Services feature to enable it to function properly. Information collected by these cookies is governed by the privacy policy of the company that created it.
   c. Cookies enable websites to recognize your device and are often used to help the website function properly and provide information to the owner(s) of the site. Cookies are necessary to recognize or "remember" anything about visitors.
   d. First-party cookies: These are sent and managed by Us.
   e. Third-party cookies: These are not owned or managed by Us, but by another entity.
   f. Further information:
      1. When you visit our sites or otherwise interact with our Service, we and our service providers acting on our behalf automatically collect certain data using tracking technologies like cookies. We use this data to understand how visitors to our websites are using them and which pages and features of the websites are most popular. This helps us improve our websites and track the performance of our advertisements.
      2. We use tracking technologies to help improve the navigation experience on our Service. We don't sell this information to third parties.
      3. Cookies and similar technologies are pieces of data contained in a very small text file that is stored in your browser and/or device. These allow our Service to identify your device as you navigate our Service.
      4. By themselves, cookies do not identify you specifically; instead, they recognize your web browser or device.

10. Children's Data
    a. You must be at least thirteen (13) years of age to use the Service, or you must have verifiable parental consent. If you are under the applicable age of majority and do not have verifiable parental consent, you shall not use and are not permitted to use the Service and you must immediately leave the Services. By using the Service, you represent and warrant that you have reached at least the age of thirteen (13) and the age of majority in your jurisdiction. We maintain a zero-tolerance policy for any minors on the Service.
    b. We do not knowingly collect personal data from anyone under the age of 13. If we discover that a person under 13 years of age has provided personal data to us, we will automatically delete such information. If you are a parent or guardian of a minor under the age of 13 and believe they have disclosed personal data to us, please contact us at EMAIL.

11. Users Outside the United States
    If you are an individual located in another jurisdiction outside of the United States ("US") or the European Economic Area, United Kingdom, or Switzerland (collectively "EEA") with laws governing data processing that differ from US or EEA laws, please be aware that data we collect will be processed and stored in the US or in other countries where we or our service providers have operations. Your use of the Service is voluntary, and you are doing so with a full understanding that by submitting your data to Us, you understand and acknowledge that your data will be transferred to the US or another jurisdiction which may not offer the same level of privacy protection as those in the country where you are located.

12. Changes and Contacting Us
    a. This Statement is current as of the Effective Date set forth above. If any changes are made, we will post them on this page. If you continue to use the Services after those changes are in effect, you agree to the changes. This Statement may change if there is a material change to the way data is processed by Us.
    b. If you have any questions or comments about this Statement, please contact us at support@agentspyglass.com or Kitai Mirai LLC, 8 The Green #13894, Dover, DE 19901, United States.

We do not sell our customer list or customer information to any other entities. Customer information such as name, email, address, mailing address, phone number, and billing information is collected for the sole purpose of providing services to our customers and notifying them of improvements to our products and services.
    `;

    const termsOfService = `
This website (the "Site") offers information regarding our services. Agentspyglass.com is a website provided to you by Kitai Mirai LLC and its related, affiliated, and/or subsidiary companies ("We," "Us," or "Our"). The following User Agreement governs Site users' ("Your" or "You") access to and use of the Site and any other information available on or through the Site ("Content").

USER AGREEMENT

Please read through this user agreement ("Agreement") before accessing, using, or browsing the Content or selecting the "I AGREE" button associated with this Content. By accessing, browsing, or using the Content and/or clicking on "I AGREE," You, on behalf of Yourself or Your entity, as applicable, agree that:

1. You have read and understand all of the terms of this Agreement;
2. You agree to be bound by all of the terms of this Agreement;
3. This Agreement is the legal equivalent of a written and signed contract between You and Us; and
4. If accessing, browsing, or using Our Content on behalf of an entity, You have full authority to bind Your entity to all of the terms of this Agreement.

If You cannot be bound or are not willing to be bound by this Agreement, then do not select the "I AGREE" button associated with this Agreement or access, browse, use, or access any of the Content. We do not grant You any right or license to access, browse, or use the Content without Your express consent to abide by the terms of this Agreement.

INFORMATIONAL PURPOSES ONLY

The Site is not intended to provide You with any financial, real estate, legal, or otherwise related advice of any kind and You understand and agree that the Site may include advertisements. Based upon information We obtain, We may make available to You advertisements that may be relevant and/or useful to You based upon Your own review.

SUBMISSIONS

All comments, concepts, feedback, graphics, ideas, illustrations, remarks, suggestions, and other materials that You communicate to Us (collectively, the "Submissions") become Our property and You waive any and all rights, except moral rights, if applicable. Further, You hereby forever assign all rights in regard to the Submissions, now known or future existing.

We will not be required to treat any Submissions as confidential and will not be liable for any of its business ideas (including but not limited to product or advertising ideas) and will not incur any liability as a result of any similarities (whether actual or perceived) that may or may not appear in future operations or other business ventures. Except as otherwise provided for in this Agreement, We will have full right of use for the Submission for any commercial or other purpose in its sole discretion without compensation to You.

Any Submissions You provide are voluntary. You are responsible for the content of the Submissions and agree to defend (at Our sole option and discretion), indemnify, and hold Us harmless from any costs, damages, losses, or expenses including attorney's fees, which We may incur as a result of Your Submissions.

INTELLECTUAL PROPERTY

"IP" means but is not limited to trademarks, service marks, patents, copyrights, privacy and publicity rights, words, graphics, logos, and any and all intellectual property the Services may contain our IP as well as IP of our affiliates or other companies, provided to You or anyone in connection with the Services. Your use of our Services does not constitute any right or license for you to use such IP. Our Services are also protected under applicable IP laws, included but not limited to those of the United States and the European Economic Area, Switzerland, and the United Kingdom (collectively "EEA"). The copying, redistribution, use, or publication by you of any portion of our Services is strictly prohibited. Your use of our Services does not grant you ownership rights of any kind in our Services. We reserve all rights not expressly granted to You in these Terms.

AGE

You must be at least 18 years of age to use the Site and any Content. By Using the Services, you represent and warrant that you have reached at least the age of eighteen (18) and the age of majority in your jurisdiction or have verifiable parental or guardian consent. We maintain a zero-tolerance policy for anyone under the age of majority on our Services who does not have verifiable parental or guardian consent.

USER ID AND PASSWORDS

Access to certain areas of the Site or the Content may be limited by a user identifier ("User ID") and password, which are selected as part of the registration process. By registering, You covenant, represent, and warrant that: (i) You are at least 18 years of age; (ii) You are using Your actual identity; (iii) You have provided only accurate, complete, current, and true information about Yourself during the registration process; and (iv) You will maintain and promptly update the information that You provide to keep it accurate, complete, current, and true.

MEMBERSHIP AGREEMENT

The Site offers optional membership subscriptions ("Memberships"). We may offer a number of subscription plans ("Plans"). If You purchase a Membership, your membership will continue and automatically renew until terminated.

To purchase a Membership, You must provide us with one or more Payment Methods. "Payment Method" means a current, valid, accepted method of payment, as may be updated from time to time and which may include payment through Your account with a third party. You must cancel your membership before it renews in order to avoid billing of the subscription fees for the next billing cycle to Your Payment Method (see "Cancellation" below).

THIRD-PARTY SERVICES

The Site includes links to third-party products, services, and websites, and may include functionality that allows for the distribution of your personal information (collectively, your "User Information") to third parties not under Our control (each, a "Third-Party Provider"). Third-Party Providers are solely responsible for their services. You are responsible for your use and submission of User Information to any third-party, and your dealings or business conducted with any third party arising in connection with the Site. Your use of third-party sites may be subject to associated third-party terms of use, privacy policies, and/or other agreements. We do not endorse and take no responsibility for such products or a Third-Party Provider's use of your User Information.

SITE MONITORING

We may monitor or review any areas on the Site where You or other users convey, transmit or post communications or communicate solely with each other for the purpose of promoting its efficiency, usefulness, and suitability of use. However, We do not undertake to screen or review every post, communication, or Submission; and We disclaim any liability related to the Content of any post, communication, or Submission, whether or not arising under the laws of copyright, libel, obscenity, privacy, trademark, or otherwise.

INTERACTIVE AREAS

We may, at Our discretion, edit, monitor, or review any areas of the Site where You transmit or post communications or communicate with Us or other users, including, but not limited to blogs, bulletin boards, chat rooms, forums, instant messaging sessions, newsgroups, and other user-interactive areas (collectively "User-Interactive Areas"), and the Content or any such communications. We, however, will have no liability related to the Content of any such communications, whether or not arising under laws of copyright, libel, obscenity, privacy, trademark, or otherwise.

COPYRIGHT

Except as otherwise provided herein, the Content contained and referenced herein is for informational purposes only. Any reproduction, retransmission, or other use is strictly prohibited. If You wish to reproduce any of the Content contained herein, please send an e-mail request to support@agentspyglass.com. Should you believe that Your work has been reproduced on the Site in a way that constitutes copyright infringement you may notify us in accordance with Title 17, United States Code, Section 512(c)(2).

TRADEMARKS

"Agent Spyglass" is Our trademark, and except as provided in this Agreement, We do not grant You any rights thereunder. As such, the Site may not be used in any way varying with the provisions of this Agreement, without Our prior written permission.

DISCLAIMERS

THE SITE MAY INCLUDE TECHNICAL INACCURACIES AND/OR TYPOGRAPHICAL ERRORS. WE MAY MAKE CHANGES OR IMPROVEMENTS AT ANY TIME WITHOUT NOTICE. THE SITE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND EITHER EXPRESSED OR IMPLIED. WE DISCLAIM, TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, ALL WARRANTIES AND CONDITIONS EXPRESSED OR IMPLIED, INCLUDING WITHOUT LIMITATION, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, AND ANY WARRANTIES CONCERNING THE AVAILABILITY, ACCURACY, COMPLETENESS, CURRENCY, RELIABILITY, QUALITY, PERFORMANCE, OR SUITABILITY OF THIS SITE, THE CONTENT, OR ANY PRODUCTS, SERVICES, OR RESULTS OBTAINED ON OR THROUGH THE SITE.

LIMITATIONS ON LIABILITY

WE WILL NOT BE LIABLE FOR ANY DAMAGES OR INJURY CAUSED BY YOUR USE OF THE SITE AND THE CONTENT, INCLUDING BUT NOT LIMITED TO, ANY COMPUTER VIRUS, DEFECT, DELAY IN OPERATION OF TRANSMISSION, ERROR, FAILURE OF PERFORMANCE, INTERRUPTION, OMISSION, OR LINE FAILURE. WE WILL NOT BE LIABLE FOR ANY DAMAGES OR INJURY, INCLUDING BUT NOT LIMITED TO, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, EXEMPLARY, CONSEQUENTIAL DAMAGES, LOST PROFITS, AND DAMAGES THAT RESULT FROM INCONVENIENCE, DELAY, OR LOSS OF USE THAT RESULT FROM THE USE OF, THE INABILITY TO USE, OR RELIANCE UPON, THE SITE, EVEN IF THERE IS NEGLIGENCE BY US OR BY AN AUTHORIZED REPRESENTATIVE, OR AN AUTHORIZED REPRESENTATIVE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, OR ANY OF THE ABOVE.

INDEMNITY

YOU AGREE TO INDEMNIFY, DEFEND AND HOLD HARMLESS US, OUR OFFICERS, DIRECTORS EMPLOYEES, AGENTS, CONTENT PROVIDERS, CUSTOMERS, AND SUPPLIERS FROM AND AGAINST ANY AND ALL ATTORNEYS' FEES, CLAIMS, COSTS AND EXPENSES, DAMAGES, INJURIES, LIABILITIES, LOSSES, PROCEEDINGS, RELATING TO OR ARISING FROM YOUR USE OF THE SITE AND ANY OF THE CONTENT, OR ANY PRODUCTS OR SERVICES OBTAINED ON OR THROUGH THE SITE.

JURISDICTION; COMPLIANCE WITH LAWS

Access, browsing, and use of the Site, the Content, and the terms and conditions of the User Agreement are governed by U.S. federal law and/or the laws of the State of Arizona, excluding any conflict of laws provisions that would refer to and apply the substantive laws of another jurisdiction.

ANY LEGAL ACTION OR PROCEEDING RELATING TO YOUR ACCESS TO OR USE OF THE SITE SHALL BE INSTITUTED ONLY IN A STATE OR FEDERAL COURT LOCATED IN THE STATE OF ARIZONA, U.S.A.; YOU AGREE TO SUBMIT TO THE JURISDICTION OF ARIZONA, AND AGREE THAT VENUE IS PROPER IN ARIZONA, THESE COURTS IN ANY SUCH LEGAL ACTION OR PROCEEDING.

ENTIRE AGREEMENT

This Agreement constitutes the sole and entire agreement between you and Us regarding the Site and supersedes all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, regarding the Site.

YOUR COMMENTS AND CONCERNS

All feedback, comments, requests for technical support, and other communications relating to the Site should be directed to: support@agentspyglass.com.

By accessing, browsing, or using the Content and/or clicking on "I AGREE," you acknowledge that you have read, understand, and agree to be bound by all the terms and conditions of this Agreement.
    `;

    const TermsModal = () => (
        <div className="terms-modal-overlay">
            <div className="terms-modal">
                <h2>{activeDocument === 'privacy' ? 'Privacy Policy' : 'Terms of Service'}</h2>
                <div className="document-tabs">
                    <button
                        className={activeDocument === 'privacy' ? 'active' : ''}
                        onClick={() => handleDocumentChange('privacy')}
                    >
                        Privacy Policy
                    </button>
                    <button
                        className={activeDocument === 'terms' ? 'active' : ''}
                        onClick={() => handleDocumentChange('terms')}
                    >
                        Terms of Service
                    </button>
                </div>
                <div className="document-content">
                    <pre>
                        {activeDocument === 'privacy' ? privacyPolicy : termsOfService}
                    </pre>
                </div>
                <div className="terms-buttons">
                    <button onClick={handleTermsAccept} className='accept'>Accept</button>
                    <button onClick={handleTermsDecline} className='decline'>Decline</button>
                </div>
                <button className="close-modal" onClick={() => setShowTermsModal(false)}>
                    <FaTimes />
                </button>
            </div>
        </div>
    );

    return (
        <div className="popup-overlay">
            <ToastContainer />
            <div className="popup-container">
                <div className="popup-content">
                    {isVerificationSent ? (
                        <div>
                            <h2>Verify Your Email</h2>
                            <p>A verification email has been sent to your account. Please check your email and click on the verification link to complete your registration.</p>
                        </div>
                    ) : (
                        <div>
                            <h2>Join Us.</h2>
                            <p className='heading-popup'>Create Account</p>
                            {error && <p className="error-message">{error}</p>}
                            <div className="signup-options">
                                <form onSubmit={handleSignUp}>
                                    <input
                                        type="text"
                                        placeholder="Username"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                        required
                                    />
                                    <input
                                        type="email"
                                        placeholder="Email Address"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                    <div className="password-input-group">
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            placeholder="Password"
                                            value={password}
                                            onChange={handlePasswordChange}
                                            onFocus={() => setPasswordFocused(true)}
                                            onBlur={() => setPasswordFocused(false)}
                                            required
                                            className={password && !Object.values(passwordRequirements).every(Boolean) ? 'invalid-password' : ''}
                                        />
                                        <button
                                            type="button"
                                            onClick={togglePasswordVisibility}
                                            className="password-toggle-btn"
                                        >
                                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                                        </button>
                                    </div>
                                    {passwordFocused && password && (
                                        <div className="password-requirements">
                                            <p>Password must:</p>
                                            <ul>
                                                <li className={passwordRequirements.length ? 'met' : 'unmet'}>
                                                    {passwordRequirements.length ? <FaCheck /> : <FaCross />} Be at least 8 characters long
                                                </li>
                                                <li className={passwordRequirements.uppercase ? 'met' : 'unmet'}>
                                                    {passwordRequirements.uppercase ? <FaCheck /> : <FaCross />} Contain at least one uppercase letter
                                                </li>
                                                <li className={passwordRequirements.lowercase ? 'met' : 'unmet'}>
                                                    {passwordRequirements.lowercase ? <FaCheck /> : <FaCross />} Contain at least one lowercase letter
                                                </li>
                                                <li className={passwordRequirements.number ? 'met' : 'unmet'}>
                                                    {passwordRequirements.number ? <FaCheck /> : <FaCross />} Contain at least one number
                                                </li>
                                                <li className={passwordRequirements.special ? 'met' : 'unmet'}>
                                                    {passwordRequirements.special ? <FaCheck /> : <FaCross />} Contain at least one special character (@$!%*?&)
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                    <div className="state-input-group">
                                        <input
                                            type="text"
                                            placeholder="State"
                                            value={stateInput}
                                            onChange={handleStateChange}
                                            onClick={() => setShowStateDropdown(true)}
                                            required
                                        />
                                        {stateInput && (
                                            <button
                                                type="button"
                                                onClick={resetState}
                                                className="reset-state-btn"
                                            >
                                                <FaTimes />
                                            </button>
                                        )}
                                        <FaChevronDown
                                            className="dropdown-icon"
                                            onClick={() => setShowStateDropdown(!showStateDropdown)}
                                        />
                                        {showStateDropdown && (
                                            <ul className="state-suggestions">
                                                {filteredStates.map((state) => (
                                                    <li key={state.abbreviation} onClick={() => selectState(state)}>
                                                        {state.name}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>
                                    <div className="terms-checkbox">
                                        <input
                                            type="checkbox"
                                            id="agreeTerms"
                                            checked={agreedToTerms}
                                            onChange={() => setShowTermsModal(true)}
                                        />
                                        <label htmlFor="agreeTerms">
                                            I agree to the terms and conditions
                                        </label>
                                    </div>
                                    <button
                                        type="submit"
                                        className="create-account-btn"
                                        disabled={isLoading || !agreedToTerms}
                                    >
                                        {isLoading ? 'Creating Account...' : 'Create Account'}
                                    </button>
                                </form>
                            </div>
                            <p className="login-link">Already have an account? <a href="#" onClick={handleSignInClick}>Log In</a></p>
                        </div>
                    )}
                </div>
                <div className="popup-right">
                    <button className="close-button" onClick={onClose}>
                        <FaTimes />
                    </button>
                    <div className="popup-right-content">
                        <p className="filler-text">Discover the world of espionage and intrigue.</p>
                    </div>
                </div>
            </div>
            {showTermsModal && <TermsModal />}
        </div>
    );
};

export default SignUpPopup;