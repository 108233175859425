import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { verifyEmail } from '../utils/auth';

const VerificationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
`;

const VerificationCard = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 40px;
  text-align: center;
  max-width: 400px;
  width: 90%;
`;

const VerificationTitle = styled.h1`
  color: #333;
  margin-bottom: 20px;
`;

const VerificationMessage = styled.p`
  color: ${props => props.isError ? '#d32f2f' : '#4caf50'};
  font-size: 18px;
`;




const EmailVerificationPage = () => {
  const { uidb64, token } = useParams();
  const navigate = useNavigate();
  const [verificationStatus, setVerificationStatus] = useState('Verifying...');
  const [isError, setIsError] = useState(false);
  const verificationAttempted = useRef(false);

  useEffect(() => {
    const verifyUserEmail = async () => {
      if (verificationAttempted.current) {
        //console.log('Verification already attempted, skipping.');
        return;
      }

      verificationAttempted.current = true;
      //console.log('Starting email verification process');

      try {
        //console.log('Calling verifyEmail function');
        const result = await verifyEmail(uidb64, token);
        //console.log('Verification result:', result);

        setVerificationStatus(result.message);
        setIsError(!result.success);

        if (result.success) {
          //console.log('Verification successful, showing success toast');
          toast.success('Email verified successfully!');
          setTimeout(() => {
            //console.log('Navigating to home page');
            navigate('/');
          }, 3000);
        } else {
          //console.log('Verification failed, showing error toast');
          toast.error(result.message || 'Failed to verify email. Please try again.');
        }
      } catch (error) {
        console.error('Unexpected error in email verification:', error);
        setVerificationStatus('An unexpected error occurred. Please try again.');
        setIsError(true);
        toast.error('An unexpected error occurred. Please try again.');
      }
    };

    verifyUserEmail();
  }, [uidb64, token, navigate]);

  //console.log('Rendering EmailVerificationPage, status:', verificationStatus, 'isError:', isError);

  return (
    <VerificationContainer>
      <VerificationCard>
        <VerificationTitle>Email Verification</VerificationTitle>
        <VerificationMessage isError={isError}>{verificationStatus}</VerificationMessage>
      </VerificationCard>
    </VerificationContainer>
  );
};

export default EmailVerificationPage;


