import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Row, Col, Alert, ProgressBar } from 'react-bootstrap';
import { uploadProperty, fetchUserProfile } from '../utils/auth';
import styled from 'styled-components';
import imageCompression from 'browser-image-compression';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress } from 'react-places-autocomplete';
import { X } from 'lucide-react';
import useGoogleMapsScript from './useGoogleMapsScript';

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1040;
`;

const StyledButton = styled(Button)`
  background-color: var(--color-blue-main);
  border-color: var(--color-blue-main);
  &:hover, &:focus, &:active {
    background-color: var(--color-blue-main);
    border-color: var(--color-blue-main);
    opacity: 0.9;
  }
`;

const ClearButton = styled(Button)`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 0;
  color: #888;
  &:hover, &:focus {
    color: #333;
    background: none;
  }
`;

const AutocompleteWrapper = styled.div`
  position: relative;
  .google-places-autocomplete__dropdown-container {
    z-index: 1000;
  }
`;

const StyledModal = styled(Modal)`
  .modal-content {
    margin-top: 80px; 
    max-height: calc(100vh - 100px); 
    overflow-y: auto;
  }
`;

const ImagePreview = styled.div`
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
  margin-bottom: 10px;
`;

const UploadPropertyPopup = ({ show, onClose, onUploadSuccess, userProfile }) => {
  const [streetAddress, setStreetAddress] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [formattedPrice, setFormattedPrice] = useState('');
  const [bedrooms, setBedrooms] = useState('');
  const [bathrooms, setBathrooms] = useState('');
  const [sqFt, setSqFt] = useState('');
  const [concession, setConcession] = useState('');
  const [formattedConcession, setFormattedConcession] = useState('');
  const [concessionType, setConcessionType] = useState('dollar');
  const [nextPropertyUrl, setNextPropertyUrl] = useState('');
  const [pdfFile, setPdfFile] = useState(null);
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [error, setError] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isPdfUploading, setIsPdfUploading] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState(null);

  const isGoogleMapsLoaded = useGoogleMapsScript();

  useEffect(() => {
    const originalConsoleError = console.error;
    console.error = (...args) => {
      if (typeof args[0] === 'string' && args[0].includes('defaultProps')) {
        return;
      }
      originalConsoleError.apply(console, args);
    };

    return () => {
      console.error = originalConsoleError;
    };
  }, []);

  useEffect(() => {
    if (show) {
      resetForm();
      checkUserProfile();
    }
  }, [show]);

  const checkUserProfile = async () => {
    if (!userProfile) {
      try {
        const profile = await fetchUserProfile();
        if (!profile || !profile.subscription?.active) {
          onClose();
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
        onClose();
      }
    } else if (!userProfile.subscription?.active) {
      onClose();
    }
  };

  const handlePlaceSelect = async (place) => {
    setSelectedPlace(place);
    if (place && place.value) {
      try {
        const results = await geocodeByAddress(place.value.description);
        if (results && results.length > 0) {
          const addressComponents = results[0].address_components;
          let streetNumber = '';
          let route = '';
          let city = '';
          let state = '';
          let zipCode = '';

          for (const component of addressComponents) {
            const types = component.types;
            if (types.includes('street_number')) {
              streetNumber = component.long_name;
            } else if (types.includes('route')) {
              route = component.short_name;
            } else if (types.includes('locality')) {
              city = component.long_name;
            } else if (types.includes('administrative_area_level_1')) {
              state = component.short_name;
            } else if (types.includes('postal_code')) {
              zipCode = component.long_name;
            }
          }

          const fullStreetAddress = `${streetNumber} ${route}`.trim();
          setStreetAddress(fullStreetAddress);
          setCity(city);
          setZipCode(zipCode);
        }
      } catch (error) {
        console.error('Error fetching address details:', error);
      }
    }
  };

  const clearAddress = () => {
    setSelectedPlace(null);
    setStreetAddress('');
    setCity('');
    setZipCode('');
  };

  const resetForm = () => {
    setStreetAddress('');
    setCity('');
    setZipCode('');
    setDescription('');
    setPrice('');
    setFormattedPrice('');
    setBedrooms('');
    setBathrooms('');
    setSqFt('');
    setConcession('');
    setFormattedConcession('');
    setConcessionType('dollar');
    setNextPropertyUrl('');
    setPdfFile(null);
    setImage(null);
    setImagePreview(null);
    setError('');
    setUploadProgress(0);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const compressImage = async (imageFile) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    };
    try {
      return await imageCompression(imageFile, options);
    } catch (error) {
      console.error("Error compressing image:", error);
      return imageFile;
    }
  };

  const formatNumberWithCommas = (value) => {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handlePriceChange = (e) => {
    const value = e.target.value.replace(/,/g, '');
    if (/^\d*$/.test(value)) {
      setFormattedPrice(formatNumberWithCommas(value));
      setPrice(value); // Store the numeric value without commas
    }
  };

  const handleConcessionChange = (e) => {
    const value = e.target.value.replace(/,/g, '');
    if (/^\d*$/.test(value)) {
      setFormattedConcession(formatNumberWithCommas(value));
      setConcession(value); // Store the numeric value without commas
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userProfile || !userProfile.subscription?.active) {
      setError('You need an active subscription to upload properties.');
      return;
    }
  
    setIsUploading(true);
    setError('');
    setUploadProgress(0);
    
    try {
      const formData = new FormData();
      formData.append('street_address', streetAddress);
      formData.append('city', city);
      formData.append('zip_code', zipCode);
      formData.append('description', description);
      formData.append('price', price);
      if (bedrooms) formData.append('bedrooms', bedrooms);
      if (bathrooms) formData.append('bathrooms', bathrooms);
      if (sqFt) formData.append('sq_ft', sqFt);
      if (concession) {
        formData.append('concession', concession);
      }
      formData.append('concession_type', concessionType);
      if (nextPropertyUrl) formData.append('next_property_url', nextPropertyUrl);
      
      if (pdfFile) {
        console.log('PDF file details:', {
          name: pdfFile.name,
          type: pdfFile.type,
          size: pdfFile.size
        });
        formData.append('pdf_file', pdfFile, pdfFile.name);
      }
      
      if (image) {
        const compressedImage = await compressImage(image);
        const imageBlob = new Blob([compressedImage], { type: 'image/jpeg' });
        formData.append('image', imageBlob, 'image.jpg');
      }
  
      const uploadedProperty = await uploadProperty(formData, (progress) => {
        setUploadProgress(progress);
      });
      const propertyWithConcessionType = {
        ...uploadedProperty,
        concession_type: concessionType
      };

      onUploadSuccess(propertyWithConcessionType);
      onClose();
    } catch (error) {
      console.error('Failed to upload property:', error);
      setError(`Failed to upload property: ${error.message}`);
    } finally {
      setIsUploading(false);
      setUploadProgress(0);
    }
  };

  return (
    <PopupOverlay style={{ display: show ? 'flex' : 'none' }}>
      <StyledModal show={show} onHide={onClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Upload Property Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
          <Form.Label>Address</Form.Label>
          <AutocompleteWrapper>
            {isGoogleMapsLoaded ? (
              <GooglePlacesAutocomplete
                apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                selectProps={{
                  value: selectedPlace,
                  onChange: handlePlaceSelect,
                  placeholder: 'Start typing an address...',
                }}
                autocompletionRequest={{
                  componentRestrictions: { country: ['us'] }
                }}
              />
            ) : (
              <div>Loading Google Maps...</div>
            )}
                
                {selectedPlace && (
                  <ClearButton onClick={clearAddress} type="button">
                    <X size={16} />
                  </ClearButton>
                )}
              </AutocompleteWrapper>
            </Form.Group>
            
            <Form.Group className="mb-3">
              <Form.Label>Street Address</Form.Label>
              <Form.Control
                type="text"
                value={streetAddress}
                onChange={(e) => setStreetAddress(e.target.value)}
                required
              />
            </Form.Group>
            
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>City</Form.Label>
                  <Form.Control 
                    type="text" 
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Zip Code</Form.Label>
                  <Form.Control 
                    type="text" 
                    value={zipCode}
                    onChange={(e) => setZipCode(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="mb-3">
              <Form.Label>Agent Remarks</Form.Label>
              <Form.Control 
                as="textarea" 
                rows={3}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>

            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Price in dollars</Form.Label>
                  <Form.Control 
                    type="text" 
                    value={formattedPrice}
                    onChange={handlePriceChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Compensation</Form.Label>
                  <div className="d-flex align-items-center">
                    <Form.Control 
                      type="text" 
                      value={formattedConcession}
                      onChange={handleConcessionChange}
                      style={{ maxWidth: '70%' }}
                    />
                    <Form.Select 
                      value={concessionType}
                      onChange={(e) => setConcessionType(e.target.value)}
                      style={{ maxWidth: '30%' }}
                    >
                      <option value="dollar">$</option>
                      <option value="percent">%</option>
                    </Form.Select>
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Bedrooms</Form.Label>
                  <Form.Control 
                    type="number" 
                    value={bedrooms}
                    onChange={(e) => setBedrooms(e.target.value)}
                    min="0" 
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Bathrooms</Form.Label>
                  <Form.Control 
                    type="number"
                    step="0.5"
                    value={bathrooms}
                    onChange={(e) => setBathrooms(e.target.value)}
                    min="0" 
                  />
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="mb-3">
              <Form.Label>Square Feet</Form.Label>
              <Form.Control 
                type="number" 
                value={sqFt}
                onChange={(e) => setSqFt(e.target.value)}
                min="0" 
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Property URL</Form.Label>
              <Form.Control 
                type="url" 
                value={nextPropertyUrl}
                onChange={(e) => setNextPropertyUrl(e.target.value)}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Contract (in .pdf format only)</Form.Label>
              <Form.Control 
                type="file" 
                onChange={(e) => {
                  setPdfFile(e.target.files[0]);
                  setIsPdfUploading(true);
                }}
                accept=".pdf"
              />
              {isPdfUploading && <span>PDF selected: {pdfFile?.name}</span>}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Property Image</Form.Label>
              {imagePreview && <ImagePreview style={{ backgroundImage: `url(${imagePreview})` }} />}
              <Form.Control 
                type="file" 
                onChange={handleImageChange}
                accept="image/*"
              />
            </Form.Group>

            {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
            <StyledButton variant="primary" type="submit" disabled={isUploading}>
              {isUploading ? 'Uploading...' : 'Upload Property'}
            </StyledButton>
            {isUploading && (
              <div className="mt-3">
                <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />
              </div>
            )}
          </Form>
        </Modal.Body>
      </StyledModal>
    </PopupOverlay>
  );
};

export default UploadPropertyPopup;