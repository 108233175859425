import React, { useState, useEffect } from 'react';
import { useParams, Navigate, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Tab, Nav, Spinner, Badge, Alert, Button } from 'react-bootstrap';
import { FaEnvelope, FaPhone, FaBuilding, FaMapMarkerAlt, FaIdCard, FaHeart, FaList, FaUser, FaFolder } from 'react-icons/fa';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import PropertyCardWrapper from './PropertyCardWrapper';
import { SavedPropertiesProvider } from './hooks/usePropertySavedState';
import { fetchAllProperties, getFolders, getFolderProperties } from '../utils/auth';

// Styled Components
const ProfileContainer = styled(Container)`
  padding-top: 120px;
  padding-bottom: 60px;
`;

const ProfileImage = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: linear-gradient(135deg, var(--color-blue-main), #2c5282);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  
  svg {
    font-size: 100px;
    color: white;
  }
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 12px 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
  transition: all 0.2s ease;
  
  &:hover {
    background-color: #e9ecef;
    transform: translateX(5px);
  }
  
  svg {
    margin-right: 12px;
    color: var(--color-blue-main);
    min-width: 20px;
    font-size: 1.2rem;
  }

  span {
    color: #4a5568;
  }
`;

const StyledCard = styled(Card)`
  border: none;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-bottom: 30px;
  
  .card-body {
    padding: 2rem;
  }
  
  h2 {
    color: #2d3748;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  
  .text-muted {
    color: #718096 !important;
  }
`;

const StyledTabs = styled(Tab.Container)`
  .nav-tabs {
    border-bottom: 2px solid var(--color-blue-main);
    margin-bottom: 30px;
    gap: 10px;
  }
  
  .nav-link {
    color: var(--color-blue-main);
    padding: 12px 24px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 12px; // Increased gap between icon and text
    border: 1px solid transparent;
    border-radius: 8px 8px 0 0;
    
    svg {
      font-size: 1.1rem;
    }
    
    &:hover {
      border-color: transparent;
      background-color: #f8f9fa;
    }
    
    &.active {
      color: white;
      background-color: var(--color-blue-main);
      border-color: var(--color-blue-main);
    }
  }
`;


const LoadingSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  flex-direction: column;
  gap: 15px;
  
  p {
    color: #4a5568;
    margin: 0;
    font-size: 1.1rem;
  }

  .spinner-border {
    width: 3rem;
    height: 3rem;
  }
`;

const ErrorMessage = styled.div`
  text-align: center;
  color: #e53e3e;
  margin: 20px 0;
  padding: 20px;
  background-color: #fff5f5;
  border-radius: 8px;
  
  h3 {
    color: #c53030;
    margin-bottom: 10px;
  }
`;

const PropertyGrid = styled(Row)`
  margin-top: 20px;
`;

const NoProperties = styled.div`
  text-align: center;
  padding: 40px 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  margin-top: 20px;
  
  svg {
    font-size: 48px;
    color: var(--color-blue-main);
    margin-bottom: 15px;
  }
  
  p {
    color: #4a5568;
    margin: 0;
    font-size: 1.1rem;
  }
`;

const TabContent = styled(Tab.Content)`
  .tab-pane {
    animation: fadeIn 0.3s ease-in;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const AgentProfile = ({ currentUser }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [agent, setAgent] = useState(null);
  const [properties, setProperties] = useState([]);
  const [savedProperties, setSavedProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [loadingSaved, setLoadingSaved] = useState(true);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [folders, setFolders] = useState([]);
  const [folderProperties, setFolderProperties] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [folderError, setFolderError] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);

  // Check subscription status when currentUser changes
  useEffect(() => {
    if (currentUser && currentUser.subscription) {
      setIsSubscribed(currentUser.subscription.active);
    } else {
      setIsSubscribed(false);
    }
  }, [currentUser]);

  useEffect(() => {
    const loadAgentData = async () => {
      try {
        setLoading(true);
        const propertiesData = await fetchAllProperties();
        const properties = propertiesData.properties || [];
        
        const agentProperty = properties.find(prop => 
          prop.owner_details && prop.owner_details.id === parseInt(id)
        );
        
        if (agentProperty?.owner_details) {
          setAgent(agentProperty.owner_details);
          const agentProperties = properties.filter(
            prop => prop.owner_details?.id === parseInt(id)
          );
          setProperties(agentProperties);
          await loadFolders();
          await loadSavedProperties(agentProperty.owner_details.id);
        } else {
          setError('Agent not found');
        }
      } catch (err) {
        console.error('Error loading agent data:', err);
        setError(err.message || 'Failed to load agent profile');
      } finally {
        setLoading(false);
      }
    };

    loadAgentData();
  }, [id]);

  const loadFolders = async () => {
    try {
      const data = await getFolders();
      setFolders(data);
      if (data.length > 0) {
        setSelectedFolder(data[0]);
        await loadFolderContents(data[0].id);
      }
    } catch (err) {
      setFolderError('Failed to load folders');
    }
  };

  const loadSavedProperties = async (agentId) => {
    try {
      setLoadingSaved(true);
      const folders = await getFolders();
      const savedPropertiesSet = new Set();
      
      for (const folder of folders) {
        const folderProperties = await getFolderProperties(folder.id);
        folderProperties.forEach(prop => {
          const propertyDetails = prop.property_details || prop;
          if (propertyDetails.owner_details?.id === agentId) {
            savedPropertiesSet.add(JSON.stringify(propertyDetails));
          }
        });
      }
      
      const uniqueSavedProps = Array.from(savedPropertiesSet).map(prop => JSON.parse(prop));
      setSavedProperties(uniqueSavedProps);
    } catch (err) {
      console.error('Error loading saved properties:', err);
    } finally {
      setLoadingSaved(false);
    }
  };

  const loadFolderContents = async (folderId) => {
    try {
      const properties = await getFolderProperties(folderId);
      const agentProperties = properties.filter(prop => {
        const propertyDetails = prop.property_details || prop;
        return propertyDetails.owner_details?.id === parseInt(id);
      });
      setFolderProperties(agentProperties);
    } catch (err) {
      setFolderError('Error loading folder properties');
    }
  };

  const handleEmailClick = (property) => {
    if (!currentUser) {
      navigate('/login');
      return;
    }
    
    const subject = `Inquiry about property: ${property.street_address}, ${property.city}`;
    const body = `Hi,\n\nI'm interested in the property at ${property.street_address}, ${property.city}. Can you please provide more information?\n\nThank you`;
    const mailtoLink = `mailto:${property.email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  const handleDocumentDownload = async (property) => {
    if (!currentUser) {
      navigate('/login');
      return;
    }

    try {
      if (property.pdf_file) {
        window.open(property.pdf_file, '_blank');
      } else {
        alert('No PDF document available for this property.');
      }
    } catch (error) {
      console.error('Error downloading document:', error);
      alert('Error accessing property documents.');
    }
  };

  const getTotalSavedProperties = () => {
    return folders.reduce((total, folder) => {
      const folderCount = folder.properties?.filter(prop => {
        const propDetails = prop.property_details || prop;
        return propDetails.owner_details?.id === parseInt(id);
      }).length || 0;
      return total + folderCount;
    }, 0);
  };

  const renderFavorites = () => {
    if (loadingSaved) {
      return (
        <LoadingSpinner>
          <Spinner animation="border" variant="primary" />
          <p>Loading saved properties...</p>
        </LoadingSpinner>
      );
    }

    return (
      <>
        {folderError && (
          <Alert variant="danger" className="mb-4" onClose={() => setFolderError(null)} dismissible>
            {folderError}
          </Alert>
        )}

        <Row>
          <Col md={4}>
            <Card className="mb-4">
              <Card.Body>
                <Card.Title>
                  <div className="d-flex align-items-center">
                    <FaFolder className="me-2" />
                    <span>Folders</span>
                  </div>
                </Card.Title>
                <Nav className="flex-column mt-3">
                  {folders.map(folder => {
                    const agentPropertiesCount = folder.properties?.filter(prop => {
                      const propDetails = prop.property_details || prop;
                      return propDetails.owner_details?.id === parseInt(id);
                    }).length || 0;
                    
                    return (
                      <Nav.Link
                        key={folder.id}
                        className={`d-flex justify-content-between align-items-center p-2 ${selectedFolder?.id === folder.id ? 'bg-light' : ''}`}
                        onClick={() => {
                          setSelectedFolder(folder);
                          loadFolderContents(folder.id);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <span>{folder.name}</span>
                        <Badge bg="secondary">{agentPropertiesCount}</Badge>
                      </Nav.Link>
                    );
                  })}
                </Nav>
              </Card.Body>
            </Card>
          </Col>

          <Col md={8}>
            {selectedFolder ? (
              <>
                <div className="mb-4">
                  <h4>{selectedFolder.name}</h4>
                  {selectedFolder.description && (
                    <p className="text-muted">{selectedFolder.description}</p>
                  )}
                </div>

                {loadingSaved ? (
                  <LoadingSpinner>
                    <Spinner animation="border" variant="primary" />
                    <p>Loading folder contents...</p>
                  </LoadingSpinner>
                ) : folderProperties.length > 0 ? (
                  <Row>
                    {folderProperties.map(property => {
                      const propertyDetails = property.property_details || property;
                      return (
                        <Col key={propertyDetails.id} md={6} className="mb-4">
                          <PropertyCardWrapper
                            property={propertyDetails}
                            viewMode="grid"
                            isSubscribed={isSubscribed}
                            currentUser={currentUser}
                            folderId={selectedFolder.id}
                            onEmailClick={handleEmailClick}
                            onDocumentDownload={handleDocumentDownload}
                            showSaveButton={true}
                            readOnly={!currentUser}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                ) : (
                  <NoProperties>
                    <FaFolder />
                    <p>No properties in this folder</p>
                  </NoProperties>
                )}
              </>
            ) : (
              <NoProperties>
                <FaFolder />
                <p>Select a folder to view its properties</p>
              </NoProperties>
            )}
          </Col>
        </Row>
      </>
    );
  };

  const renderProperties = (propertyList, emptyMessage, isLoading = false) => {
    if (isLoading) {
      return (
        <LoadingSpinner>
          <Spinner animation="border" variant="primary" />
          <p>Loading properties...</p>
        </LoadingSpinner>
      );
    }

    return propertyList.length > 0 ? (
      <PropertyGrid>
        <Row>
          {propertyList.map(property => (
            <Col key={property.id} md={6} className="mb-4">
              <PropertyCardWrapper
                property={property}
                viewMode="grid"
                isSubscribed={isSubscribed}
                currentUser={currentUser}
                onSaveStatusChange={() => loadSavedProperties(parseInt(id))}
                onEmailClick={handleEmailClick}
                onDocumentDownload={handleDocumentDownload}
              />
            </Col>
          ))}
        </Row>
      </PropertyGrid>
    ) : (
      <NoProperties>
        <FaBuilding />
        <p>{emptyMessage}</p>
      </NoProperties>
    );
  };

  if (loading) {
    return (
      <ProfileContainer>
        <LoadingSpinner>
          <Spinner animation="border" variant="primary" />
          <p>Loading agent profile...</p>
        </LoadingSpinner>
      </ProfileContainer>
    );
  }

  if (error) {
    return (
      <ProfileContainer>
        <ErrorMessage>
          <h3>Error</h3>
          <p>{error}</p>
        </ErrorMessage>
      </ProfileContainer>
    );
  }

  if (!agent) {
    return <Navigate to="/agents" replace />;
  }

  return (
    <SavedPropertiesProvider>
      <ProfileContainer>
        <Row className="justify-content-center mb-5">
          <Col md={10} lg={8}>
            <StyledCard>
              <Card.Body>
                <Row>
                  <Col md={4} className="text-center">
                    <ProfileImage>
                      <FaUser />
                    </ProfileImage>
                    <h2>{agent.name || agent.username}</h2>
                    {agent.profile?.state_id && (
                      <p className="text-muted">ID: {agent.profile.state_id}</p>
                    )}
                  </Col>
                  <Col md={8}>
                    {agent.email && (
                      <InfoItem>
                        <FaEnvelope />
                        <span>{agent.email}</span>
                      </InfoItem>
                    )}
                    {agent.profile?.phone && (
                      <InfoItem>
                        <FaPhone />
                        <span>{agent.profile.phone}</span>
                      </InfoItem>
                    )}
                    {agent.profile?.company && (
                      <InfoItem>
                        <FaBuilding />
                        <span>{agent.profile.company}</span>
                      </InfoItem>
                    )}
                    {agent.profile?.business_address && (
                      <InfoItem>
                        <FaMapMarkerAlt />
                        <span>{agent.profile.business_address}</span>
                      </InfoItem>
                    )}
                    {agent.profile?.state && (
                      <InfoItem>
                        <FaIdCard />
                        <span>Licensed in {agent.profile.state}</span>
                      </InfoItem>
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </StyledCard>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md={10} lg={8}>
            <StyledTabs defaultActiveKey="listings" id="agent-properties">
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="listings" className="d-flex align-items-center">
                    <FaList />
                    <span>Listings ({properties.length})</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="favorites" className="d-flex align-items-center">
                    <FaHeart />
                    <span>Saved Properties ({getTotalSavedProperties()})</span>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <TabContent>
                <Tab.Pane eventKey="listings">
                  {renderProperties(properties, 'No listings available')}
                </Tab.Pane>
                <Tab.Pane eventKey="favorites">
                  {currentUser ? (
                    renderFavorites()
                  ) : (
                    <Alert variant="info">
                      <p>Sign in to save properties to your folders.</p>
                      <Button 
                        variant="primary" 
                        onClick={() => navigate('/login')}
                        className="mt-2"
                      >
                        Sign In
                      </Button>
                    </Alert>
                  )}
                </Tab.Pane>
              </TabContent>
            </StyledTabs>
          </Col>
        </Row>
      </ProfileContainer>
    </SavedPropertiesProvider>
  );
};

AgentProfile.propTypes = {
  currentUser: PropTypes.object
};

AgentProfile.defaultProps = {
  currentUser: null
};

export default AgentProfile;