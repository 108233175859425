import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Form, Button, ListGroup, Alert } from 'react-bootstrap';
import { FaUser, FaSearch, FaTimes } from 'react-icons/fa';
import { property_search } from '../utils/auth';
import debounce from 'lodash/debounce';

const HomeCover = ({ page, userProfile, onUpdateProfile, onSearch, onResetSearch }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showSuggestions, setShowSuggestions] = useState(true);

  const debouncedFetchSuggestions = useCallback(
    debounce(async (query) => {
      if (query.length >= 3 && showSuggestions) {
        try {
          const response = await property_search(query);
          setSuggestions(response.slice(0, 5).map(property => ({
            id: property.id,
            text: `${property.street_address}, ${property.city}, ${property.zip_code}`
          })));
        } catch (err) {
          console.error('Error fetching suggestions:', err);
          setSuggestions([]);
        }
      } else {
        setSuggestions([]);
      }
    }, 300),
    [showSuggestions]
  );

  useEffect(() => {
    debouncedFetchSuggestions(searchQuery);
    return () => debouncedFetchSuggestions.cancel();
  }, [searchQuery, debouncedFetchSuggestions]);

  const handleSearch = async (e) => {
    e.preventDefault();
    if (searchQuery.trim().length === 0) {
      setError(null);
      onResetSearch();
      return;
    }
    setIsLoading(true);
    setError(null);
    setShowSuggestions(false);
    try {
      const results = await property_search(searchQuery);
      if (results.length === 0) {
        const partialResults = await property_search(searchQuery.split(',')[0]);
        if (partialResults.length > 0) {
          onSearch(partialResults);
        } else {
          setError('Sorry, no properties found in that area.');
          onResetSearch();
        }
      } else {
        onSearch(results);
      }
    } catch (err) {
      console.error('Error fetching search results:', err);
      setError('An error occurred while searching. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleSuggestionClick = async (suggestion) => {
    setSearchQuery(suggestion.text);
    setSuggestions([]);
    setIsLoading(true);
    setError(null);
    try {
      const results = await property_search(suggestion.text);
      if (results.length === 0) {
        const partialResults = await property_search(suggestion.text.split(',')[0]);
        if (partialResults.length > 0) {
          onSearch(partialResults);
        } else {
          setError('Sorry, no properties found for this address.');
          onResetSearch();
        }
      } else {
        onSearch(results);
      }
    } catch (err) {
      console.error('Error fetching search results:', err);
      setError('An error occurred while searching. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetSearch = () => {
    setSearchQuery('');
    setSuggestions([]);
    setShowSuggestions(true);
    setError(null);
    onResetSearch();
  };

  const renderSearchSection = () => (
    <>
      
      <Row className="justify-content-center">
        <Col lg={8} md={10} sm={12}>
          <Form onSubmit={handleSearch} className="d-flex flex-column flex-md-row">
            <div className="position-relative w-100 me-md-2 mb-2 mb-md-0">
              <Form.Control
                type="text"
                placeholder="Search for properties"
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                  setShowSuggestions(true);
                  if (e.target.value.trim().length === 0) {
                    setError(null);
                    onResetSearch();
                  }
                }}
                disabled={isLoading}
              />
              {searchQuery && (
                <Button
                  variant="link"
                  className="position-absolute top-50 end-0 translate-middle-y"
                  onClick={handleResetSearch}
                >
                  <FaTimes />
                </Button>
              )}
              {suggestions.length > 0 && showSuggestions && (
                <ListGroup className="position-absolute w-100 mt-1 z-index-1000">
                  {suggestions.map((suggestion) => (
                    <ListGroup.Item 
                      key={suggestion.id} 
                      action 
                      onClick={() => handleSuggestionClick(suggestion)}
                    >
                      {suggestion.text}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </div>
            <Button 

              type="submit" 
              className="flex-shrink-0"
              disabled={isLoading}
              style={{ backgroundColor: 'var(--color-blue-main)' }}
            >
              {isLoading ? 'Searching...' : <><FaSearch  /> Search</>}
            </Button>
          </Form>
          {error && searchQuery.trim().length > 0 && <Alert variant="danger" className="mt-3">{error}</Alert>}
        </Col>
      </Row>
    </>
  );

  const renderProfileSection = () => (
    <Row className="justify-content-center">
      <Col md={10} lg={8}>
        <div className="profile-card">
        <div className="text-center mb-4">
  <FaUser size={96} className="profile-icon mb-3" />
  <h2 className="mb-3">{userProfile.username}</h2>
</div>
          <Row className="justify-content-center">
  <Col md={5} className="text-left" style={{ textAlign: 'left' }}>
    <p className="mb-2"><strong>Full Name:</strong> {userProfile.name || 'Not set'}</p>
    <p className="mb-2"><strong>Email:</strong> {userProfile.email}</p>
    <p className="mb-2"><strong>Phone:</strong> {userProfile.profile?.phone || 'Not set'}</p>
    <p className="mb-2"><strong>Company:</strong> {userProfile.profile?.company || 'Not set'}</p>
  </Col>
  <Col md={5} className="text-left" style={{ textAlign: 'left' }}>
    <p className="mb-2"><strong>State:</strong> {userProfile.profile?.state || 'Not set'}</p>
    <p className="mb-2"><strong>State ID:</strong> {userProfile.profile?.state_id || 'Not set'}</p>
    <p className="mb-2"><strong>Business Address:</strong> {userProfile.profile?.business_address || 'Not set'}</p>
  </Col>
</Row>
         <div className="text-center mt-4">
            <Button
              variant="primary"
              onClick={onUpdateProfile}
              style={{ backgroundColor: "#18407E" }}
            >
              Update Profile
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
  return (
    <section className="home-cover hero-section">
    <Container>
      {page === 'profile' && userProfile ? renderProfileSection() : renderSearchSection()}
    </Container>
  </section>
  );
};

export default HomeCover;