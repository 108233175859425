import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Button, Pagination, ButtonGroup, Table, Alert, Form } from 'react-bootstrap';
import { FaThLarge, FaList, FaTable, FaPlus } from 'react-icons/fa';
import PropertyCardWrapper from './PropertyCardWrapper';
import { API_URL } from '../config';
import SubscriptionPopup from './SubscriptionPopup';
import UploadPropertyPopup from './UploadProperty';
import { fetchUploads, fetchAllProperties, deleteProperty, fetchUserProfile, updateUserProfile, fetchPropertyDetails, enableMockGeolocation, disableMockGeolocation } from '../utils/auth';
import { FaEnvelope, FaDownload, FaExternalLinkAlt } from 'react-icons/fa';
import { MdVerified } from 'react-icons/md';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import styled from 'styled-components';
import PropertyCard from './PropertyCard';
import SignUpPopup from './SignUpPopup';
import { SavedPropertiesProvider } from './hooks/usePropertySavedState';

const BlurredText = styled.div`
  filter: blur(3px);
  user-select: none;
`;

const StyledButton = styled(Button)`
  background-color: var(--color-blue-main);
  color: #fff;
  border-color: var(--color-blue-main);
  &:hover, &:focus, &:active {
    background-color: var(--color-blue-main);
    border-color: var(--color-blue-main);
    opacity: 0.9;
  }
`;

const UploadCard = styled(Button)`
  height: 100%;
  width: 100%;
  background-color: #f8f9fa;
  border: 2px dashed var(--color-blue-main);
  color: var(--color-blue-main);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding:2rem;
  font-size: 1.5rem;
  &:hover {
    background-color: var(--color-blue-main);
    color: #fff;
  }
`;
const OverlayCard = styled.div`
  position: relative;
  height: 100%;
`;

const SubscribeOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  padding: 20px;
`;

const Feed = ({ userProfile, onUserProfileUpdate, searchResults }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [showSubscriptionPopup, setShowSubscriptionPopup] = useState(false);
  const [showUploadPropertyPopup, setShowUploadPropertyPopup] = useState(false);
  const [properties, setProperties] = useState([]);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [viewMode, setViewMode] = useState('grid');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showSignInPopup, setShowSignInPopup] = useState(false);
  const itemsPerPage = 10;
  const [favorites, setFavorites] = useState([]);
  const [editingProperty, setEditingProperty] = useState(null);
  const [editingConcessionType, setEditingConcessionType] = useState('dollar');
  const [showEditPropertyModal, setShowEditPropertyModal] = useState(false);
  const [uploads, setUploads] = useState([]);
  const [useMockGeolocation, setUseMockGeolocation] = useState(false);
  const [warning, setWarning] = useState(null);
  const [locationError, setLocationError] = useState(null);
  const getUploads = async () => {
    try {
      const data = await fetchUploads();
      if (Array.isArray(data)) {
        setUploads(data);
      } else {
        console.error('Fetched data is not an array:', data);
      }
    } catch (error) {
      console.error('Error fetching uploads:', error);
    }
  };
  useEffect(() => {
    // Enable mock geolocation by default
    enableMockGeolocation();
    loadAllProperties();
  }, []);
  const handleMockGeolocationToggle = (event) => {
    const isChecked = event.target.checked;
    setUseMockGeolocation(isChecked);
    if (isChecked) {
      enableMockGeolocation();
    } else {
      disableMockGeolocation();
    }
    loadAllProperties();
  };

  const formatNumberWithCommas = (value) => {
    const wholeNumber = value.toString().replace(/,/g, '').split('.')[0];
    return wholeNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    const storedFavorites = JSON.parse(localStorage.getItem('favorites')) || [];
    setFavorites(storedFavorites);
  }, []);

  const handleToggleFavorite = useCallback((propertyId, isFavorite) => {
    setFavorites(prevFavorites => {
      const newFavorites = isFavorite
        ? [...prevFavorites, propertyId]
        : prevFavorites.filter(id => id !== propertyId);
      localStorage.setItem('favorites', JSON.stringify(newFavorites));
      return newFavorites;
    });
  }, []);

  const checkSubscriptionStatus = useCallback(() => {
    if (userProfile && userProfile.subscription) {
      setIsSubscribed(userProfile.subscription.active);
    } else {
      setIsSubscribed(false);
    }
  }, [userProfile]);

  const loadAllProperties = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    setWarning(null);
    setLocationError(null);
    try {
      const { properties, warning } = await fetchAllProperties();
      if (warning?.includes("current location") && warning?.includes("differs from your profile state")) {
        setLocationError("Access Denied: You can only view properties in your registered state. Please update your location or contact support if you believe this is an error.");
        setProperties([]);
      } else {
        setProperties(properties);
        setWarning(warning);
      }
    } catch (error) {
      console.error('Error fetching properties:', error);
      if (error.response?.status === 403) {
        setLocationError("Access Denied: You can only view properties in your registered state.");
      } else {
        setError('Failed to load properties. Please try again later.');
      }
      setProperties([]);
    } finally {
      setIsLoading(false);
    }
  }, []);


  useEffect(() => {
    if (searchResults && Array.isArray(searchResults)) {
      setProperties(searchResults);
      setCurrentPage(1);
    } else if (!searchResults) {
      loadAllProperties();
    }
    checkSubscriptionStatus();
  }, [loadAllProperties, checkSubscriptionStatus, searchResults]);

  const handleUploadClick = () => {
    if (!userProfile || !userProfile.subscription?.active) {
      setShowSubscriptionPopup(true);
    } else {
      setShowUploadPropertyPopup(true);
    }
  };

  const handleSubscriptionSuccess = async (updatedProfile) => {
    onUserProfileUpdate(updatedProfile);
    setIsSubscribed(true);
    setShowSubscriptionPopup(false);
    loadAllProperties();
    try {
      const latestProfile = await fetchUserProfile();
      onUserProfileUpdate(latestProfile);
    } catch (error) {
      console.error('Error fetching updated user profile:', error);
    }
  };


  const handleCloseUploadPropertyPopup = (uploadedProperty) => {
    setShowUploadPropertyPopup(false);
    if (uploadedProperty) {
      setProperties(prevProperties => [uploadedProperty, ...prevProperties]);
    } else {
      loadAllProperties();
    }
  };
  const handleDocumentDownload = async (property) => {
    try {
      const propertyDetails = await fetchPropertyDetails(property.id);
      if (propertyDetails.pdf_file) {
        window.open(propertyDetails.pdf_file, '_blank');
      } else {
        alert('No PDF document available for this property.');
      }
    } catch (error) {
      console.error('Error fetching property details:', error);
      alert('Error accessing property documents. You may not have permission to view this property.');
    }
  };

  const handleEditProperty = (property) => {
    const formattedProperty = {
      ...property,
      price: formatNumberWithCommas(Math.floor(property.price)),
      concession: formatNumberWithCommas(Math.floor(property.concession))
    };
    setEditingProperty(formattedProperty);
    setEditingConcessionType(property.concession_type || 'dollar');
    setShowEditPropertyModal(true);
  };

  const handleDeleteProperty = async (propertyId) => {
    if (window.confirm('Are you sure you want to delete this property?')) {
      try {
        await deleteProperty(propertyId);
        loadAllProperties(); // Reload properties after deletion
      } catch (error) {
        console.error('Error deleting property:', error);
        alert('Failed to delete property. Please try again.');
      }
    }
  };

  const handleEmailClick = (property) => {
    if (!userProfile) {
      setShowSignInPopup(true);
    } else {
      const subject = `Inquiry about property: ${property.street_address}, ${property.city}`;
      const body = `Hi,\n\nI'm interested in the property at ${property.street_address}, ${property.city}. Can you please provide more information?\n\nThank you,\n${userProfile.username}`;
      const mailtoLink = `mailto:${property.email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      window.location.href = mailtoLink;
    }
  };

  const PropertyTable = ({ properties }) => {
    const formatNumber = (num) => {
      return Math.floor(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const formatConcession = (concession, type) => {
      if (type === 'dollar' || type === undefined) {
        return `$${formatNumber(concession)}`;
      } else {
        return `${concession}%`;
      }
    };

    const renderConcession = (property) => {
      if (!property.concession) return 'N/A';

      const concessionText = `${formatConcession(property.concession, property.concession_type)}`;

      if (isSubscribed) {
        return concessionText;
      } else {
        return (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="subscribe-tooltip">Subscribe to view compensation</Tooltip>}
          >
            <BlurredText>{concessionText}</BlurredText>
          </OverlayTrigger>
        );
      }
    };

    const Property = (property) => {
      const formattedProperty = {
        ...property,
        price: formatNumberWithCommas(Math.floor(property.price)),
        concession: formatNumberWithCommas(Math.floor(property.concession))
      };
      setEditingProperty(formattedProperty);
      setEditingConcessionType(property.concession_type || 'dollar');
      setShowEditPropertyModal(true);
    };

    const handleDeleteProperty = async (propertyId) => {
      if (window.confirm('Are you sure you want to delete this property?')) {
        try {
          await deleteProperty(propertyId);
          getUploads();
        } catch (error) {
          console.error('Error deleting property:', error);
        }
      }
    };


    return (
      <Table striped bordered hover>
        <thead>
          <tr style={{ backgroundColor: 'var(--color-blue-main)', color: 'white' }}>
            <th>Address</th>
            <th>Price</th>
            <th>Compensation</th>
            <th>Property Size</th>
            <th>Documents</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {properties.map((property) => {
            const isVerified = property.street_address && property.city && property.zip_code &&
              property.price && property.bedrooms && property.bathrooms &&
              property.sq_ft && property.description && property.pdf_file && property.image;

            const sizeInfo = [
              property.bedrooms ? `${property.bedrooms} bed` : null,
              property.bathrooms ? `${property.bathrooms} bath` : null,
              property.sq_ft ? `${formatNumber(property.sq_ft)} sqft` : null
            ].filter(Boolean).join(' || ');

            return (
              <tr key={property.id}>
                <td>{property.street_address && property.city && property.zip_code ?
                  `${property.street_address}, ${property.city}, ${property.zip_code}` : 'N/A'}</td>
                <td>
                  {property.price ? `$${formatNumber(property.price)}` : 'N/A'}
                  {isVerified && <MdVerified className="text-success ms-2" />}
                </td>
                <td>{renderConcession(property)}</td>
                <td>{sizeInfo || 'N/A'}</td>
                <td>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    {property.pdf_file && (
                      <StyledButton variant="link" onClick={() => handleDocumentDownload(property)}>
                        <FaDownload /> PDF
                      </StyledButton>
                    )}
                    {property.next_property_url && (
                      <a
                        href={property.next_property_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: 'var(--color-blue-main)' }}
                      >
                        Property URL
                      </a>
                    )}
                  </div>
                </td>
                <td>
                  <StyledButton variant="outline-primary" size="sm" onClick={() => handleEmailClick(property)}>
                    <FaEnvelope /> Email
                  </StyledButton>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Array.isArray(properties) ? properties.slice(indexOfFirstItem, indexOfLastItem) : [];

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (locationError) {
    return (
      <Alert variant="danger" className="m-3">
        <Alert.Heading>Location Restriction</Alert.Heading>
        <p>{locationError}</p>
        <hr />
        <p className="mb-0">
          Your registered state: {userProfile?.profile?.state || 'Not set'}
        </p>
        {useMockGeolocation ? (
          <p>Currently using mocked location (California)</p>
        ) : (
          <p>Using your current location</p>
        )}
      </Alert>
    );
  }

  if (error) {
    return <Alert variant="danger">{error}</Alert>;
  }

  return (
    <SavedPropertiesProvider>
    <div className="feed">
      <Container>
        {warning && <Alert variant="warning">{warning}</Alert>}
        <div className="d-flex justify-content-between align-items-center mb-3">
          <Form.Check
            type="switch"
            id="mock-geolocation-switch"
            label="Use California Location"
            checked={useMockGeolocation}
            onChange={handleMockGeolocationToggle}
          />

          <ButtonGroup>
            {['grid', 'list', 'table'].map((mode) => (
              <Button
                key={mode}
                variant={viewMode === mode ? 'primary' : 'outline-primary'}
                onClick={() => setViewMode(mode)}
                style={{
                  backgroundColor: viewMode === mode ? 'var(--color-blue-main)' : 'transparent',
                  borderColor: 'var(--color-blue-main)',
                  color: viewMode === mode ? '#fff' : 'var(--color-blue-main)'
                }}
              >
                {mode === 'grid' && <FaThLarge />}
                {mode === 'list' && <FaList />}
                {mode === 'table' && <FaTable />}
                {` ${mode.charAt(0).toUpperCase() + mode.slice(1)}`}
              </Button>
            ))}
          </ButtonGroup>
        </div>
        <Row>
          {viewMode !== 'table' && (
            <Col lg={viewMode === 'list' ? 12 : 6} xl={viewMode === 'list' ? 12 : 4} className="mb-4">
              <OverlayCard>
                <UploadCard onClick={handleUploadClick}>
                  <FaPlus />
                  <div>Upload Property</div>
                </UploadCard>
                {(!userProfile || !userProfile.subscription?.active) && (
                  <SubscribeOverlay>
                    <h4>Subscribe to Upload Properties</h4>
                    <StyledButton onClick={() => setShowSubscriptionPopup(true)}>
                      Subscribe Now
                    </StyledButton>
                  </SubscribeOverlay>
                )}
              </OverlayCard>
            </Col>
          )}
          {viewMode === 'table' ? (
            <Col xs={12}>
              <PropertyTable
                properties={currentItems}
                isSubscribed={isSubscribed}
                handleDocumentDownload={handleDocumentDownload}
                handleEmailClick={handleEmailClick}
              />
            </Col>
          ) : (
            currentItems.map((property) => (
              <Col
                key={property.id}
                lg={viewMode === 'list' ? 12 : 6}
                xl={viewMode === 'list' ? 12 : 4}
                className="mb-4"
              >
                <PropertyCardWrapper
                  property={property}
                  viewMode={viewMode}
                  isSubscribed={isSubscribed}
                  onEmailClick={handleEmailClick}
                  onDocumentDownload={handleDocumentDownload}
                  onEdit={handleEditProperty}
                  onDelete={handleDeleteProperty}
                  currentUser={userProfile}
                />
              </Col>
            ))
          )}
        </Row>

        {properties.length > 0 && (
          <div className="d-flex justify-content-center mt-4">
            <Pagination>
              {Array.from({ length: Math.ceil(properties.length / itemsPerPage) }).map((_, index) => (
                <Pagination.Item
                  key={index}
                  active={index + 1 === currentPage}
                  onClick={() => paginate(index + 1)}
                >
                  {index + 1}
                </Pagination.Item>
              ))}
            </Pagination>
          </div>
        )}
      </Container>
      {showSignInPopup && (
        <SignUpPopup
          onClose={() => setShowSignInPopup(false)}
          onSignUpSuccess={(user) => {
            onUserProfileUpdate(user);
            setShowSignInPopup(false);
          }}
        />
      )}
      {showSubscriptionPopup && (
        <SubscriptionPopup
          onClose={() => setShowSubscriptionPopup(false)}
          onSubscribe={handleSubscriptionSuccess}
          userProfile={userProfile}
        />
      )}
      <UploadPropertyPopup
        show={showUploadPropertyPopup}
        onClose={() => setShowUploadPropertyPopup(false)}
        onUploadSuccess={handleCloseUploadPropertyPopup}
        userProfile={userProfile}
      />
    </div>
    </SavedPropertiesProvider>
  );
};

export default Feed;