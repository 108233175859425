import React, { useEffect, useState, useRef } from 'react';
import { Modal, Form, Container, Row, Col, Button } from 'react-bootstrap';
import styled from 'styled-components';
import ProfileGrid from './ProfileGrid';
import HomeCover from './HomeCover';
import { fetchUserProfile, updateUserProfile } from '../utils/auth';
import usStates from '../utils/usStates';
import { FaChevronDown, FaTimes } from 'react-icons/fa';
import { X } from 'lucide-react';

const StyledModal = styled(Modal)`
  .modal-dialog {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
  }
`;

const StyledButton = styled(Button)`
  background-color: var(--color-blue-main);
  border-color: var(--color-blue-main);
  &:hover, &:focus, &:active {
    background-color: var(--color-blue-main);
    border-color: var(--color-blue-main);
    opacity: 0.9;
  }
`;

const StateInputGroup = styled.div`
  position: relative;
  margin-bottom: 1rem;

  input {
    width: 100%;
    padding: 10px 40px 10px 10px;
    border: 1px solid var(--color-blue-main);
    border-radius: 5px;
    font-size: 16px;
    transition: border-color 0.3s ease;

    &:focus {
      outline: none;
      border-color: var(--color-blue-main);
      box-shadow: 0 0 0 2px rgba(24, 64, 126, 0.2);
    }
  }

  .dropdown-icon,
  .reset-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    cursor: pointer;
    color: var(--color-blue-main);
    transition: color 0.3s ease;
  }

  .dropdown-icon {
    right: 10px;
  }

  .reset-icon {
    right: 30px;
  }

  .dropdown-icon:hover,
  .reset-icon:hover {
    color: var(--color-black);
  }
`;

const StateSuggestions = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid var(--color-blue-main);
  border-top: none;
  border-radius: 0 0 5px 5px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0;
  margin: 0;

  li {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    list-style: none;

    &:hover {
      background-color: rgba(24, 64, 126, 0.1);
    }
  }
`;

const AutocompleteWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const AddressInput = styled(Form.Control)`
  padding-right: 30px;
  &:disabled {
    background-color: #f8f9fa;
    cursor: not-allowed;
  }
`;

const ResetButton = styled.button`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: #888;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    color: #333;
  }
`;

const SuggestionsList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
  width: 95%;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
`;

const SuggestionItem = styled.li`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const LoadingText = styled.div`
  color: #666;
  font-size: 0.9rem;
  margin-top: 5px;
`;

const Profile = ({ onLogout }) => {
  const [userProfile, setUserProfile] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [updatedFields, setUpdatedFields] = useState({});
  const [showStateDropdown, setShowStateDropdown] = useState(false);
  const [filteredStates, setFilteredStates] = useState(usStates);
  const [suggestions, setSuggestions] = useState([]);
  const [isGoogleLoaded, setIsGoogleLoaded] = useState(false);
  const addressInputRef = useRef(null);
  const autocompleteService = useRef(null);
  const placesService = useRef(null);

  useEffect(() => {
    const checkGoogleMapsLoaded = () => {
      if (window.google && window.google.maps && window.google.maps.places) {
        setIsGoogleLoaded(true);
        autocompleteService.current = new window.google.maps.places.AutocompleteService();
        placesService.current = new window.google.maps.places.PlacesService(
          document.createElement('div')
        );
      }
    };

    if (window.google && window.google.maps && window.google.maps.places) {
      checkGoogleMapsLoaded();
    } else {
      const listener = window.setInterval(() => {
        if (window.google && window.google.maps && window.google.maps.places) {
          checkGoogleMapsLoaded();
          clearInterval(listener);
        }
      }, 100);

      return () => clearInterval(listener);
    }
  }, []);

  // Fetch user profile on component mount
  useEffect(() => {
    getUserProfile();
  }, []);

  const getUserProfile = async () => {
    try {
      const data = await fetchUserProfile();
      setUserProfile(data);
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  };

  const handleUpdateProfile = () => {
    setShowUpdateModal(true);
    setUpdatedFields({
      name: userProfile?.name || '',
      phone: userProfile?.profile?.phone || '',
      company: userProfile?.profile?.company || '',
      state: userProfile?.profile?.state || '',
      state_id: userProfile?.profile?.state_id || '',
      business_address: userProfile?.profile?.business_address || ''
    });
  };

  const handleCloseModal = () => {
    setShowUpdateModal(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedFields(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmitUpdate = async (e) => {
    e.preventDefault();
    try {
      const fieldsToUpdate = {};
      for (const [key, value] of Object.entries(updatedFields)) {
        if (value !== userProfile[key] && value !== userProfile.profile[key]) {
          fieldsToUpdate[key] = value;
        }
      }
      
      if (Object.keys(fieldsToUpdate).length === 0) {
        return;
      }

      const updatedData = await updateUserProfile(fieldsToUpdate);
      setUserProfile(prevProfile => ({
        ...prevProfile,
        ...updatedData.user,
        profile: {
          ...prevProfile.profile,
          ...updatedData.user.profile
        }
      }));
      setShowUpdateModal(false);
      getUserProfile();
    } catch (error) {
      console.error('Error updating user profile:', error);
    }
  };

  const selectState = (state) => {
    setUpdatedFields(prev => ({
      ...prev,
      state: state.name
    }));
    setShowStateDropdown(false);
  };

  const resetState = () => {
    setUpdatedFields(prev => ({
      ...prev,
      state: ''
    }));
    setFilteredStates(usStates);
  };

  const handleAddressChange = (e) => {
    const value = e.target.value;
    setUpdatedFields(prev => ({ ...prev, business_address: value }));
    
    if (value.length > 2 && isGoogleLoaded && autocompleteService.current) {
      autocompleteService.current.getPlacePredictions(
        { 
          input: value, 
          componentRestrictions: { country: 'us' } 
        },
        (predictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
            setSuggestions(predictions);
          } else {
            setSuggestions([]);
          }
        }
      );
    } else {
      setSuggestions([]);
    }
  };

  const handleSuggestionSelect = (placeId) => {
    const suggestion = suggestions.find(s => s.place_id === placeId);
    const value = suggestion?.description || '';
    setUpdatedFields(prev => ({ ...prev, business_address: value }));
    setSuggestions([]);
    
    if (isGoogleLoaded && placesService.current && placeId) {
      placesService.current.getDetails(
        { placeId },
        (place, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setUpdatedFields(prev => ({ 
              ...prev, 
              business_address: place.formatted_address 
            }));
          }
        }
      );
    }
  };

  const resetAddress = () => {
    setUpdatedFields(prev => ({ ...prev, business_address: '' }));
    setSuggestions([]);
    if (addressInputRef.current) {
      addressInputRef.current.focus();
    }
  };

  return (
    <>
      <HomeCover 
        page="profile" 
        userProfile={userProfile} 
        onUpdateProfile={handleUpdateProfile}
      />
      <Container>
        <Row>
          <Col>
            <ProfileGrid userProfile={userProfile} />
          </Col>
        </Row>
      </Container>

      <StyledModal show={showUpdateModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Update Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmitUpdate}>
            <Form.Group className="mb-3">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={updatedFields.name || ''}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={userProfile?.email || ''}
                readOnly
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="tel"
                name="phone"
                value={updatedFields.phone || ''}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Company</Form.Label>
              <Form.Control
                type="text"
                name="company"
                value={updatedFields.company || ''}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>State</Form.Label>
              <StateInputGroup>
                <Form.Control
                  type="text"
                  name="state"
                  value={updatedFields.state || ''}
                  onChange={handleInputChange}
                  onClick={() => setShowStateDropdown(true)}
                />
                <FaChevronDown 
                  className="dropdown-icon" 
                  onClick={() => setShowStateDropdown(!showStateDropdown)} 
                />
                {updatedFields.state && (
                  <FaTimes 
                    className="reset-icon" 
                    onClick={resetState} 
                  />
                )}
                {showStateDropdown && (
                  <StateSuggestions>
                    {filteredStates.map((state) => (
                      <li key={state.abbreviation} onClick={() => selectState(state)}>
                        {state.name}
                      </li>
                    ))}
                  </StateSuggestions>
                )}
              </StateInputGroup>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>State ID</Form.Label>
              <Form.Control
                type="text"
                name="state_id"
                value={updatedFields.state_id || ''}
                readOnly
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Business Address</Form.Label>
              <AutocompleteWrapper>
                <AddressInput
                  ref={addressInputRef}
                  type="text"
                  name="business_address"
                  value={updatedFields.business_address || ''}
                  onChange={handleAddressChange}
                  placeholder={!isGoogleLoaded ? "Loading Google Maps..." : "Enter address..."}
                  disabled={!isGoogleLoaded}
                />
                {updatedFields.business_address && (
                  <ResetButton onClick={resetAddress} type="button">
                    <X size={16} />
                  </ResetButton>
                )}
                {!isGoogleLoaded && (
                  <LoadingText>Loading Google Maps integration...</LoadingText>
                )}
              </AutocompleteWrapper>
              {suggestions.length > 0 && (
                <SuggestionsList>
                  {suggestions.map((suggestion) => (
                    <SuggestionItem
                      key={suggestion.place_id}
                      onClick={() => handleSuggestionSelect(suggestion.place_id)}
                    >
                      {suggestion.description}
                    </SuggestionItem>
                  ))}
                </SuggestionsList>
              )}
            </Form.Group>

            <StyledButton type="submit">Save Changes</StyledButton>
          </Form>
        </Modal.Body>
      </StyledModal>
    </>
  );
};

export default Profile;