import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchUserProfile } from '../utils/auth';

const SubscriptionSuccess = () => {
  const navigate = useNavigate();
  const [verificationStatus, setVerificationStatus] = useState('Verifying subscription...');

  useEffect(() => {
    const verifySubscription = async () => {
      try {
        const profile = await fetchUserProfile();
        if (profile.subscription && profile.subscription.active) {
          setVerificationStatus('Subscription verified successfully!');
          setTimeout(() => navigate('/properties'), 2000);
        } else {
          setVerificationStatus('Subscription verification failed. Please contact support.');
        }
      } catch (error) {
        console.error('Error verifying subscription:', error);
        setVerificationStatus('Error verifying subscription. Please try again later.');
      }
    };

    verifySubscription();
  }, [navigate]);

  return (
    <div>
      <h2>Subscription Status</h2>
      <p>{verificationStatus}</p>
    </div>
  );
};

export default SubscriptionSuccess;