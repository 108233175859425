import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Modal, Form, Alert, Pagination } from 'react-bootstrap';
import styled from 'styled-components';
import { fetchUploads, deleteProperty, fetchBillingInfo, updateBillingInfo, updateProperty, changePassword, fetchPropertyDetails } from '../utils/auth';
import PropertyCard from './PropertyCard';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress } from 'react-places-autocomplete';
import { X } from 'lucide-react';
import useGoogleMapsScript from './useGoogleMapsScript';
import SavedPropertiesList from './SavedPropertiesList';

const StyledButton = styled(Button)`
  background-color: transparent;
  border-color: var(--color-blue-main);
  color: var(--color-blue-main);
  &:hover, &:focus, &:active {
    background-color: var(--color-blue-main);
    border-color: var(--color-blue-main);
    color: white;
  }
`;

const StyledModal = styled(Modal)`
  .modal-dialog {
    display: flex;
    margin-top: 80px; 
    overflow-y: auto;
  }
`;


const AutocompleteWrapper = styled.div`
  position: relative;
  .google-places-autocomplete__dropdown-container {
    z-index: 1000;
  }
`;

const ClearButton = styled(Button)`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 0;
  color: #888;
  &:hover, &:focus {
    color: #333;
    background: none;
  }
`;


const ProfileGrid = ({ userProfile }) => {
  const [selectedOption, setSelectedOption] = useState('uploads');
  const [currentPage, setCurrentPage] = useState(1);
  const [uploads, setUploads] = useState([]);
  const [billingInfo, setBillingInfo] = useState(null);
  const [showBillingModal, setShowBillingModal] = useState(false);
  const [updatedBillingDetails, setUpdatedBillingDetails] = useState('');
  const [showEditPropertyModal, setShowEditPropertyModal] = useState(false);
  const [editingProperty, setEditingProperty] = useState(null);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordChangeMessage, setPasswordChangeMessage] = useState(null);
  const [editingConcessionType, setEditingConcessionType] = useState('dollar');
  const [selectedPlace, setSelectedPlace] = useState(null);
  const uploadsPerPage = 2;
  const [favorites, setFavorites] = useState([]);
  const [favoritesProperties, setFavoritesProperties] = useState([]);
  const [error, setError] = useState(null);

  const isGoogleMapsLoaded = useGoogleMapsScript();

  useEffect(() => {
    const storedFavorites = JSON.parse(localStorage.getItem('favorites')) || [];
    setFavorites(storedFavorites);
  }, []);

  useEffect(() => {
    if (userProfile && userProfile.id !== null) {
      if (userProfile.subscription?.active) {
        getUploads();
        getBillingInfo();
      }
    }
  }, [userProfile]);

  useEffect(() => {
    const fetchFavoriteProperties = async () => {
      const favoriteProps = uploads.filter(prop => favorites.includes(prop.id));
      setFavoritesProperties(favoriteProps);
    };
    fetchFavoriteProperties();
  }, [favorites, uploads]);

  const formatNumberWithCommas = (value) => {
    const wholeNumber = value.toString().replace(/,/g, '').split('.')[0];
    return wholeNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handlePriceChange = (e) => {
    const value = e.target.value.replace(/[^\d]/g, '');
    setEditingProperty(prev => ({
      ...prev,
      price: value ? formatNumberWithCommas(value) : ''
    }));
  };

  const handleConcessionChange = (e) => {
    const value = e.target.value.replace(/[^\d]/g, '');
    setEditingProperty(prev => ({
      ...prev,
      concession: value ? formatNumberWithCommas(value) : ''
    }));
  };

  const getUploads = async () => {
    try {
      const data = await fetchUploads();
      if (Array.isArray(data)) {
        setUploads(data);
      } else {
        console.error('Fetched data is not an array:', data);
      }
    } catch (error) {
      console.error('Error fetching uploads:', error);
    }
  };

  const handleSaveProperty = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      Object.keys(editingProperty).forEach(key => {
        if (editingProperty[key] !== null && editingProperty[key] !== undefined && editingProperty[key] !== '') {
          if (key === 'pdf_file' || key === 'image') {
            if (editingProperty[key] instanceof File) {
              formData.append(key, editingProperty[key]);
            }
          } else if (key === 'price' || key === 'concession') {
            formData.append(key, editingProperty[key].replace(/,/g, ''));
          } else {
            formData.append(key, editingProperty[key]);
          }
        }
      });
      formData.append('concession_type', editingConcessionType);

      const updatedProperty = await updateProperty(editingProperty.id, formData);
      setUploads(prevUploads => prevUploads.map(prop =>
        prop.id === updatedProperty.id ? { ...updatedProperty, concession_type: editingConcessionType } : prop
      ));

      setShowEditPropertyModal(false);
    } catch (error) {
      console.error('Error updating property:', error);
    }
  };

  const getBillingInfo = async () => {
    try {
      const data = await fetchBillingInfo();
      setBillingInfo(data);
    } catch (error) {
      console.error('Error fetching billing info:', error);
    }
  };

  const handleEditProperty = async (property) => {
    try {
      const propertyDetails = await fetchPropertyDetails(property.id);
      const formattedProperty = {
        ...propertyDetails,
        price: formatNumberWithCommas(Math.floor(propertyDetails.price)),
        concession: formatNumberWithCommas(Math.floor(propertyDetails.concession))
      };
      setEditingProperty(formattedProperty);
      setEditingConcessionType(propertyDetails.concession_type || 'dollar');
      setShowEditPropertyModal(true);
    } catch (error) {
      console.error('Error fetching property details:', error);
      if (error.response?.status === 403) {
        alert('You do not have permission to edit this property. It may be outside your current state.');
      }
    }
  };

  const handleToggleFavorite = (propertyId, isFavorite) => {
    setFavorites(prevFavorites => {
      const newFavorites = isFavorite
        ? [...prevFavorites, propertyId]
        : prevFavorites.filter(id => id !== propertyId);
      localStorage.setItem('favorites', JSON.stringify(newFavorites));
      return newFavorites;
    });
  };

  const handleDeleteProperty = async (propertyId) => {
    if (window.confirm('Are you sure you want to delete this property?')) {
      try {
        await deleteProperty(propertyId);
        getUploads();
      } catch (error) {
        console.error('Error deleting property:', error);
      }
    }
  };

  const handleDocumentDownload = async (property) => {
    try {
      const propertyDetails = await fetchPropertyDetails(property.id);
      if (propertyDetails.pdf_file) {
        window.open(propertyDetails.pdf_file, '_blank');
      } else {
        alert('No PDF document available for this property.');
      }
    } catch (error) {
      console.error('Error fetching property details:', error);
      alert('Error accessing property documents. You may not have permission to view this property.');
    }
  };

  const handleFileInputChange = (e) => {
    const { name, files } = e.target;
    setEditingProperty(prev => ({
      ...prev,
      [name]: files[0]
    }));
  };

  const handlePropertyInputChange = (e) => {
    const { name, value } = e.target;
    setEditingProperty(prev => ({
      ...prev,
      [name]: e.target.type === 'number' ? (value === '' ? '' : Number(value)) : value
    }));
  };

  const handleUpdateBillingInfo = async () => {
    try {
      await updateBillingInfo({ billing_details: updatedBillingDetails });
      getBillingInfo();
      setShowBillingModal(false);
    } catch (error) {
      console.error('Error updating billing info:', error);
    }
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setPasswordChangeMessage(null);

    if (newPassword !== confirmPassword) {
      setPasswordChangeMessage({ type: 'danger', text: 'New password and confirm password do not match.' });
      return;
    }

    try {
      const response = await changePassword(oldPassword, newPassword, confirmPassword);
      setPasswordChangeMessage({ type: 'success', text: response.message });
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      setPasswordChangeMessage({ type: 'danger', text: error.message });
    }
  };

  const handleEmailClick = (property) => {
    if (!userProfile) return;
    
    const subject = `Inquiry about property: ${property.street_address}, ${property.city}`;
    const body = `Hi,\n\nI'm interested in the property at ${property.street_address}, ${property.city}. Can you please provide more information?\n\nThank you,\n${userProfile.username}`;
    const mailtoLink = `mailto:${property.email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };


  const handlePlaceSelect = async (place) => {
    setSelectedPlace(place);
    if (place && place.value) {
      try {
        const results = await geocodeByAddress(place.value.description);
        if (results && results.length > 0) {
          const addressComponents = results[0].address_components;
          let streetNumber = '';
          let route = '';
          let city = '';
          let state = '';
          let zipCode = '';

          for (const component of addressComponents) {
            const types = component.types;
            if (types.includes('street_number')) {
              streetNumber = component.long_name;
            } else if (types.includes('route')) {
              route = component.short_name;
            } else if (types.includes('locality')) {
              city = component.long_name;
            } else if (types.includes('administrative_area_level_1')) {
              state = component.short_name;
            } else if (types.includes('postal_code')) {
              zipCode = component.long_name;
            }
          }

          const fullStreetAddress = `${streetNumber} ${route}`.trim();
          setEditingProperty(prev => ({
            ...prev,
            street_address: fullStreetAddress,
            city,
            state,
            zip_code: zipCode
          }));
        }
      } catch (error) {
        console.error('Error fetching address details:', error);
      }
    }
  };

  const clearAddress = () => {
    setSelectedPlace(null);
    setEditingProperty(prev => ({
      ...prev,
      street_address: '',
      city: '',
      state: '',
      zip_code: ''
    }));
  };
  
  const indexOfLastUpload = currentPage * uploadsPerPage;
  const indexOfFirstUpload = indexOfLastUpload - uploadsPerPage;
  const currentUploads = uploads.slice(indexOfFirstUpload, indexOfLastUpload);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const renderContent = () => {
    switch (selectedOption) {
      case 'uploads':
        return (
          <div>
            <h3>Your Uploaded Properties</h3>
            {uploads.length === 0 ? (
              <p>You haven't uploaded any properties yet.</p>
            ) : (
              <Row>
                {currentUploads.map((property) => (
                  <Col key={property.id} md={6} className="mb-4">
                    <PropertyCard
                      property={property}
                      isSubscribed={true}
                      onEmailClick={handleEmailClick}
                      onDocumentDownload={handleDocumentDownload}
                      onEdit={handleEditProperty}
                      onDelete={handleDeleteProperty}
                      currentUser={userProfile}
                      onToggleFavorite={handleToggleFavorite}
                      isUploadSection={true}
                    />
                  </Col>
                ))}
              </Row>
            )}
            <div className="d-flex justify-content-center mt-4">
              <Pagination>
                {Array.from({ length: Math.ceil(uploads.length / uploadsPerPage) }).map((_, index) => (
                  <Pagination.Item
                    key={index}
                    active={index + 1 === currentPage}
                    onClick={() => paginate(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
              </Pagination>
            </div>
          </div>
        );
      case 'billing':
        return (
          <div>
            <h3>Billing Information</h3>
            {billingInfo ? (
              <div>
                <p><strong>Billing Details:</strong> {billingInfo.billing_details}</p>
                <StyledButton onClick={() => setShowBillingModal(true)}>
                  Update Billing Info
                </StyledButton>
              </div>
            ) : (
              <div>
                <p>No billing information found.</p>
                <StyledButton onClick={() => setShowBillingModal(true)}>
                  Add Billing Info
                </StyledButton>
              </div>
            )}
          </div>
        );
      case 'account':
        return (
          <div>
            <h3>Account Settings</h3>
            <Form onSubmit={handlePasswordChange}>
              <Form.Group className="mb-3">
                <Form.Label>Old Password</Form.Label>
                <Form.Control
                  type="password"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
              <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Confirm New Password</Form.Label>
                <Form.Control
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </Form.Group>
              {passwordChangeMessage && (
                <Alert variant={passwordChangeMessage.type} className="mt-3">
                  {passwordChangeMessage.text}
                </Alert>
              )}
              <StyledButton type="submit">Change Password</StyledButton>
            </Form>
          </div>
        );
      case 'favorites':
        return <SavedPropertiesList userProfile={userProfile} />;
      default:
        return null;
    }
  };

  if (!userProfile) {
    return <div>Loading...</div>;
  }

  return (
    <div className="profile-grid">
      <Container>
        <Row>
          <Col md={4}>
            <div className="options">
              <ul>
                <li
                  className={selectedOption === 'uploads' ? 'active' : ''}
                  onClick={() => setSelectedOption('uploads')}
                >
                  Your Uploads
                </li>
                <li
                  className={selectedOption === 'favorites' ? 'active' : ''}
                  onClick={() => setSelectedOption('favorites')}
                >
                  Favorites
                </li>
                <li
                  className={selectedOption === 'billing' ? 'active' : ''}
                  onClick={() => setSelectedOption('billing')}
                >
                  Billing Information
                </li>
                <li
                  className={selectedOption === 'account' ? 'active' : ''}
                  onClick={() => setSelectedOption('account')}
                >
                  Account Settings
                </li>
              </ul>
            </div>
          </Col>
          <Col md={8}>{renderContent()}</Col>
        </Row>
      </Container>

      <StyledModal show={showBillingModal} onHide={() => setShowBillingModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{billingInfo ? 'Update' : 'Add'} Billing Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Billing Details</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={updatedBillingDetails}
                onChange={(e) => setUpdatedBillingDetails(e.target.value)}
                placeholder="Enter your billing details..."
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowBillingModal(false)}>
            Close
          </Button>
          <StyledButton onClick={handleUpdateBillingInfo}>
            {billingInfo ? 'Update' : 'Add'} Billing Info
          </StyledButton>
        </Modal.Footer>
      </StyledModal>

      <StyledModal show={showEditPropertyModal} onHide={() => setShowEditPropertyModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Property</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSaveProperty}>
            {/* Regular address inputs instead of Google Maps */}
            <Form.Group className="mb-3">
              <Form.Label>Street Address</Form.Label>
              <Form.Control
                type="text"
                name="street_address"
                value={editingProperty?.street_address || ''}
                onChange={handlePropertyInputChange}
                required
              />
            </Form.Group>
            
            <Row>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    value={editingProperty?.city || ''}
                    onChange={handlePropertyInputChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    type="text"
                    name="state"
                    value={editingProperty?.state || ''}
                    onChange={handlePropertyInputChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Zip Code</Form.Label>
                  <Form.Control
                    type="text"
                    name="zip_code"
                    value={editingProperty?.zip_code || ''}
                    onChange={handlePropertyInputChange}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="mb-3">
              <Form.Label>Address</Form.Label>
              <AutocompleteWrapper>
                {isGoogleMapsLoaded ? (
                  <GooglePlacesAutocomplete
                    apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                    selectProps={{
                      value: selectedPlace,
                      onChange: handlePlaceSelect,
                      placeholder: 'Start typing an address...',
                    }}
                    autocompletionRequest={{
                      componentRestrictions: { country: ['us'] }
                    }}
                  />
                ) : (
                  <div>Loading Google Maps...</div>
                )}
                
                {selectedPlace && (
                  <ClearButton onClick={clearAddress} type="button">
                    <X size={16} />
                  </ClearButton>
                )}
              </AutocompleteWrapper>
            </Form.Group> 

            <Form.Group className="mb-3">
              <Form.Label>Agent Remarks</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                value={editingProperty?.description || ''}
                onChange={handlePropertyInputChange}
              />
            </Form.Group>

            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Price in dollars</Form.Label>
                  <Form.Control
                    type="text"
                    name="price"
                    value={editingProperty?.price || ''}
                    onChange={handlePriceChange}
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Compensation</Form.Label>
                  <div className="d-flex align-items-center">
                    <Form.Control
                      type="text"
                      name="concession"
                      value={editingProperty?.concession || ''}
                      onChange={handleConcessionChange}
                      style={{ maxWidth: '70%' }}
                    />
                    <Form.Select
                      value={editingConcessionType}
                      onChange={(e) => setEditingConcessionType(e.target.value)}
                      style={{ maxWidth: '30%' }}
                    >
                      <option value="dollar">$</option>
                      <option value="percent">%</option>
                    </Form.Select>
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Bedrooms</Form.Label>
                  <Form.Control
                    type="number"
                    name="bedrooms"
                    value={editingProperty?.bedrooms || ''}
                    onChange={handlePropertyInputChange}
                    min="0"
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Bathrooms</Form.Label>
                  <Form.Control
                    type="number"
                    name="bathrooms"
                    value={editingProperty?.bathrooms || ''}
                    onChange={handlePropertyInputChange}
                    min="0"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="mb-3">
              <Form.Label>Square Feet</Form.Label>
              <Form.Control
                type="number"
                name="sq_ft"
                value={editingProperty?.sq_ft || ''}
                onChange={handlePropertyInputChange}
                min="0"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Property URL</Form.Label>
              <Form.Control
                type="url"
                name="next_property_url"
                value={editingProperty?.next_property_url || ''}
                onChange={handlePropertyInputChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Contract (in .pdf format only)</Form.Label>
              <Form.Control
                type="file"
                name="pdf_file"
                onChange={handleFileInputChange}
                accept=".pdf"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Property Image</Form.Label>
              <Form.Control
                type="file"
                name="image"
                onChange={handleFileInputChange}
                accept="image/*"
              />
            </Form.Group>

            <StyledButton type="submit">Save Changes</StyledButton>
          </Form>
        </Modal.Body>
      </StyledModal>
    </div>
  );
};

export default ProfileGrid;