import React, { useState, useEffect } from 'react';
import { SavedPropertiesProvider } from './hooks/usePropertySavedState';
import { Button, Modal, Spinner, Alert } from 'react-bootstrap';
import {
  Folder,
  Trash2,
  FolderPlus,
  Edit2,
  Search,
  ChevronRight,
  Plus,
  Heart,
  ChevronDown,
  ChevronUp
} from 'lucide-react';
import {
  getFolders,
  getFolderProperties,
  removePropertyFromFolder,
  deleteFolder,
  updateFolder,
  createFolder,
  movePropertyBetweenFolders
} from '../utils/auth';
import PropertyCardWrapper from './PropertyCardWrapper';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// Styled Components
const Container = styled.div`
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.05);
  padding: 24px;
`;

const FolderGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  margin-bottom: 24px;
`;

const FolderCard = styled.div`
  background: ${props => props.active ? '#f0f7ff' : '#fff'};
  border: 2px solid ${props => props.active ? 'var(--color-blue-main)' : '#e5e7eb'};
  border-radius: 12px;
  padding: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  min-height: 160px;
  display: flex;
  flex-direction: column;

  &:hover {
    border-color: var(--color-blue-main);
    transform: translateY(-2px);
  }

  .folder-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 12px;
    flex: 1;
  }

  .folder-title {
    display: flex;
    align-items: flex-start;
    gap: 12px;
  }

  .folder-icon {
    margin-top: 4px;
  }

  .folder-count {
    background: ${props => props.active ? 'var(--color-blue-main)' : '#f3f4f6'};
    color: ${props => props.active ? '#fff' : '#4b5563'};
    padding: 4px 12px;
    border-radius: 16px;
    font-size: 13px;
    font-weight: 500;
  }

  .folder-content {
    flex: 1;
  }

  .folder-name {
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 8px;
    color: var(--color-blue-main);
  }

  .folder-description {
    color: #6b7280;
    font-size: 14px;
    line-height: 1.5;
    margin-top: 8px;
    margin-bottom: 12px;
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

  .folder-actions {
    display: flex;
    gap: 8px;
    position: absolute;
    bottom: 12px;
    right: 12px;
    opacity: 0;
    transition: all 0.2s ease;
  }

  &:hover .folder-actions {
    opacity: 1;
  }

  .view-properties {
    margin-top: auto;
    padding-top: 12px;
    border-top: 1px solid #e5e7eb;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #6b7280;
    font-size: 0.9rem;
  }
`;

const SearchBar = styled.div`
  position: relative;
  margin-bottom: 24px;

  input {
    width: 100%;
    padding: 12px 16px 12px 44px;
    border: 2px solid #e5e7eb;
    border-radius: 12px;
    font-size: 15px;
    transition: all 0.2s ease;

    &:focus {
      outline: none;
      border-color: var(--color-blue-main);
      box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
    }
  }

  svg {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    color: #9ca3af;
  }
`;

const PropertyGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
  padding: 20px 0;
`;

const EmptyState = styled.div`
  text-align: center;
  padding: 48px 24px;
  background: #f9fafb;
  border-radius: 12px;
  border: 2px dashed #e5e7eb;

  svg {
    width: 48px;
    height: 48px;
    color: #9ca3af;
    margin-bottom: 16px;
  }

  h4 {
    font-size: 18px;
    font-weight: 600;
    color: #1f2937;
    margin-bottom: 8px;
  }

  p {
    color: #6b7280;
    margin-bottom: 24px;
  }
`;

const PropertyNotes = styled.div`
  background: #f8f9fa;
  border-radius: 8px;
  padding: 12px;
  margin-top: -12px;
  margin-bottom: 24px;

  .notes-header {
    font-weight: 500;
    color: #4b5563;
    margin-bottom: 8px;
  }

  .notes-content {
    color: #6b7280;
    font-size: 0.9rem;
    line-height: 1.5;
  }

  .show-more-button {
    background: none;
    border: none;
    color: var(--color-blue-main);
    padding: 0;
    font-size: 0.85rem;
    cursor: pointer;
    margin-top: 4px;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const StyledModal = styled(Modal)`
  .modal-content {
    border-radius: 12px;
    border: none;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  }

  .modal-header {
    border-bottom: 1px solid #e5e7eb;
    padding: 1.5rem;
  }

  .modal-body {
    padding: 1.5rem;
  }

  .modal-footer {
    border-top: 1px solid #e5e7eb;
    padding: 1.25rem;
  }
`;

const SavedPropertiesListContent = ({ userProfile }) => {
  // State declarations
  const [folders, setFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [folderProperties, setFolderProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedDescriptions, setExpandedDescriptions] = useState({});
  const [expandedNotes, setExpandedNotes] = useState({});

  // Modal states
  const [showNewFolderModal, setShowNewFolderModal] = useState(false);
  const [showEditFolderModal, setShowEditFolderModal] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showRemovePropertyModal, setShowRemovePropertyModal] = useState(false);
  const [showMovePropertyModal, setShowMovePropertyModal] = useState(false);

  // Form states
  const [newFolderData, setNewFolderData] = useState({ name: '', description: '' });
  const [editingFolder, setEditingFolder] = useState(null);
  const [folderToDelete, setFolderToDelete] = useState(null);
  const [propertyToRemove, setPropertyToRemove] = useState(null);
  const [propertyToMove, setPropertyToMove] = useState(null);
  const [targetFolderId, setTargetFolderId] = useState('');

  useEffect(() => {
    loadFolders();
  }, []);

  const loadFolders = async () => {
    try {
      setLoading(true);
      const data = await getFolders();
      
      // Ensure folder descriptions are properly set
      const foldersWithDescriptions = data.map(folder => ({
        ...folder,
        description: folder.description || '' // Default to empty string if null/undefined
      }));
      
      setFolders(foldersWithDescriptions);
      
      if (foldersWithDescriptions.length > 0 && !selectedFolder) {
        setSelectedFolder(foldersWithDescriptions[0]);
        await loadFolderProperties(foldersWithDescriptions[0].id);
      }
    } catch (err) {
      console.error('Error loading folders:', err);
      setError('Failed to load folders');
    } finally {
      setLoading(false);
    }
  };

  const loadFolderProperties = async (folderId) => {
    try {
      const properties = await getFolderProperties(folderId);
      
      // Ensure property notes are properly set
      const propertiesWithNotes = properties.map(property => ({
        ...property,
        notes: property.notes || '',
        property_details: property.property_details ? {
          ...property.property_details,
          notes: property.notes || ''
        } : null
      }));
      
      setFolderProperties(propertiesWithNotes);
    } catch (err) {
      setError('Error loading properties');
    }
  };

  const handleCreateFolder = async (e) => {
    e.preventDefault();
    try {
      const newFolder = await createFolder(
        newFolderData.name.trim(),
        newFolderData.description.trim()
      );
      
      setShowNewFolderModal(false);
      setNewFolderData({ name: '', description: '' });
      await loadFolders();
      setSelectedFolder(newFolder);
      await loadFolderProperties(newFolder.id);
    } catch (err) {
      setError('Failed to create folder');
    }
  };

  const handleUpdateFolder = async (e) => {
    e.preventDefault();
    try {
      await updateFolder(editingFolder.id, {
        name: editingFolder.name,
        description: editingFolder.description || '' // Ensure description is never undefined
      });
      setShowEditFolderModal(false);
      await loadFolders();
    } catch (err) {
      setError('Failed to update folder');
    }
  };

  const handleDeleteFolder = async () => {
    try {
      await deleteFolder(folderToDelete.id);
      
      if (selectedFolder?.id === folderToDelete.id) {
        const remainingFolders = folders.filter(f => f.id !== folderToDelete.id);
        setSelectedFolder(remainingFolders[0] || null);
        if (remainingFolders[0]) {
          await loadFolderProperties(remainingFolders[0].id);
        } else {
          setFolderProperties([]);
        }
      }
      
      setShowDeleteConfirm(false);
      setFolderToDelete(null);
      await loadFolders();
    } catch (err) {
      setError('Failed to delete folder');
    }
  };

  const toggleDescription = (folderId) => {
    setExpandedDescriptions(prev => ({
      ...prev,
      [folderId]: !prev[folderId]
    }));
  };

  const toggleNotes = (propertyId) => {
    setExpandedNotes(prev => ({
      ...prev,
      [propertyId]: !prev[propertyId]
    }));
  };

  const renderFolderCard = (folder) => (
    <FolderCard
      key={folder.id}
      active={selectedFolder?.id === folder.id}
      onClick={() => {
        setSelectedFolder(folder);
        loadFolderProperties(folder.id);
      }}
    >
      <div className="folder-header">
        <div className="folder-title">
          <Folder
            size={20}
            className={`folder-icon ${selectedFolder?.id === folder.id ? 'text-primary' : 'text-gray-400'}`}
          />
          <div className="folder-content">
            <div className="folder-name">{folder.name}</div>
            {folder.description && folder.description.trim() !== '' && (
              <div className="folder-description">
                {expandedDescriptions[folder.id]
                  ? folder.description
                  : `${folder.description.slice(0, 100)}${folder.description.length > 100 ? '...' : ''}`
                }
                {folder.description.length > 100 && (
                  <button
                    className="show-more-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleDescription(folder.id);
                    }}
                  >
                    {expandedDescriptions[folder.id] ? (
                      <span>Show less <ChevronUp size={14} /></span>
                    ) : (
                      <span>Show more <ChevronDown size={14} /></span>
                    )}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
        <span className="folder-count">
          {folder.property_count} {folder.property_count === 1 ? 'property' : 'properties'}
        </span>
      </div>

      <div className="folder-actions">
        <Button
          variant="link"
          className="p-1"
          onClick={(e) => {
            e.stopPropagation();
            setEditingFolder({
              id: folder.id,
              name: folder.name,
              description: folder.description || ''
            });
            setShowEditFolderModal(true);
          }}
        >
          <Edit2 size={16} />
        </Button>
        <Button
          variant="link"
          className="p-1 text-danger"
          onClick={(e) => {
            e.stopPropagation();
            setFolderToDelete(folder);
            setShowDeleteConfirm(true);
          }}
        >
          <Trash2 size={16} />
        </Button>
      </div>

      {folder.id === selectedFolder?.id && (
        <div className="view-properties">
          <span>View properties</span>
          <ChevronRight size={16} /></div>
      )}
    </FolderCard>
  );

  const renderPropertyCard = (property) => {
    const propertyDetails = property.property_details || property;
    
    return (
      <div key={propertyDetails.id}>
        <PropertyCardWrapper
          property={propertyDetails}
          viewMode="grid"
          isSubscribed={true}
          currentUser={userProfile}
          folderId={selectedFolder?.id}
          onEmailClick={(prop) => {
            if (!userProfile) return;
            const subject = `Inquiry about property: ${prop.street_address}, ${prop.city}`;
            const body = `Hi,\n\nI'm interested in the property at ${prop.street_address}, ${prop.city}. Can you please provide more information?\n\nThank you,\n${userProfile.username}`;
            const mailtoLink = `mailto:${prop.email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
            window.location.href = mailtoLink;
          }}
          onRemoveFromFolder={(prop) => {
            setPropertyToRemove(prop);
            setShowRemovePropertyModal(true);
          }}
          onMoveProperty={(prop) => {
            setPropertyToMove(prop);
            setShowMovePropertyModal(true);
          }}
        />
        {property.notes && (
          <PropertyNotes>
            <div className="notes-header">Notes</div>
            <div className="notes-content">
              {expandedNotes[propertyDetails.id]
                ? property.notes
                : `${property.notes.slice(0, 100)}${property.notes.length > 100 ? '...' : ''}`
              }
              {property.notes.length > 100 && (
                <button
                  className="show-more-button"
                  onClick={() => toggleNotes(propertyDetails.id)}
                >
                  {expandedNotes[propertyDetails.id] ? (
                    <span>Show less <ChevronUp size={14} /></span>
                  ) : (
                    <span>Show more <ChevronDown size={14} /></span>
                  )}
                </button>
              )}
            </div>
          </PropertyNotes>
        )}
      </div>
    );
  };

  const handleRemoveProperty = async () => {
    try {
      await removePropertyFromFolder(selectedFolder.id, propertyToRemove.id);
      setShowRemovePropertyModal(false);
      setPropertyToRemove(null);
      await loadFolderProperties(selectedFolder.id);
      await loadFolders();
    } catch (err) {
      setError('Failed to remove property');
    }
  };

  const handleMoveProperty = async () => {
    try {
      await movePropertyBetweenFolders(
        propertyToMove.id,
        selectedFolder.id,
        targetFolderId
      );
      setShowMovePropertyModal(false);
      setPropertyToMove(null);
      setTargetFolderId('');
      await loadFolderProperties(selectedFolder.id);
      await loadFolders();
    } catch (err) {
      setError('Failed to move property');
    }
  };

  const filteredProperties = searchTerm
    ? folderProperties.filter(property =>
        property.street_address?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        property.city?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        property.state?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        property.zip_code?.includes(searchTerm)
      )
    : folderProperties;

  if (loading) {
    return (
      <Container className="text-center py-5">
        <Spinner animation="border" variant="primary" className="mb-3" />
        <p className="text-muted">Loading your folders...</p>
      </Container>
    );
  }

  return (
    <Container>
      {/* Header */}
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h3 className="m-0">Saved Properties</h3>
        <Button
          variant="outline-primary"
          className="d-flex align-items-center gap-2"
          onClick={() => setShowNewFolderModal(true)}
        >
          <Plus size={18} />
          New Folder
        </Button>
      </div>

      {/* Error Alert */}
      {error && (
        <Alert variant="danger" className="mb-4" onClose={() => setError(null)} dismissible>
          {error}
        </Alert>
      )}

      {/* Folder Grid */}
      {folders.length > 0 ? (
        <>
          <FolderGrid>
            {folders.map(renderFolderCard)}
          </FolderGrid>

          {/* Selected Folder Content */}
          {selectedFolder && (
            <>
              <SearchBar>
                <Search size={20} />
                <input
                  type="text"
                  placeholder={`Search properties in "${selectedFolder.name}"...`}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </SearchBar>

              {filteredProperties.length > 0 ? (
                <PropertyGrid>
                  {filteredProperties.map(renderPropertyCard)}
                </PropertyGrid>
              ) : (
                <EmptyState>
                  <Search size={48} />
                  <h4>{searchTerm ? 'No matching properties' : 'No saved properties'}</h4>
                  <p>
                    {searchTerm
                      ? 'Try adjusting your search terms'
                      : `Save some properties to see them in "${selectedFolder.name}"`}
                  </p>
                </EmptyState>
              )}
            </>
          )}
        </>
      ) : (
        <EmptyState>
          <Folder size={48} />
          <h4>No Folders Yet</h4>
          <p>Create your first folder to start organizing saved properties</p>
          <Button
            variant="primary"
            className="d-flex align-items-center gap-2 mx-auto"
            onClick={() => setShowNewFolderModal(true)}
          >
            <FolderPlus size={18} />
            Create First Folder
          </Button>
        </EmptyState>
      )}

      {/* New Folder Modal */}
      <StyledModal show={showNewFolderModal} onHide={() => setShowNewFolderModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Folder</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleCreateFolder}>
            <div className="mb-3">
              <label className="form-label fw-medium">Folder Name *</label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter folder name"
                value={newFolderData.name}
                onChange={(e) => setNewFolderData(prev => ({ ...prev, name: e.target.value }))}
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label fw-medium">
                Description <span className="text-muted">(Optional)</span>
              </label>
              <textarea
                className="form-control"
                placeholder="Add a description for this folder"
                value={newFolderData.description}
                onChange={(e) => setNewFolderData(prev => ({ ...prev, description: e.target.value }))}
                rows={3}
              />
            </div>
            <div className="d-flex justify-content-end gap-2">
              <Button variant="outline-secondary" onClick={() => setShowNewFolderModal(false)}>
                Cancel
              </Button>
              <Button type="submit" variant="primary">
                Create Folder
              </Button>
            </div>
          </form>
        </Modal.Body>
      </StyledModal>

      {/* Edit Folder Modal */}
      <StyledModal show={showEditFolderModal} onHide={() => setShowEditFolderModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Folder</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleUpdateFolder}>
            <div className="mb-3">
              <label className="form-label fw-medium">Folder Name</label>
              <input
                type="text"
                className="form-control"
                value={editingFolder?.name || ''}
                onChange={(e) => setEditingFolder(prev => ({ ...prev, name: e.target.value }))}
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label fw-medium">Description (Optional)</label>
              <textarea
                className="form-control"
                value={editingFolder?.description || ''}
                onChange={(e) => setEditingFolder(prev => ({ ...prev, description: e.target.value }))}
                rows={3}
              />
            </div>
            <div className="d-flex justify-content-end gap-2">
              <Button variant="outline-secondary" onClick={() => setShowEditFolderModal(false)}>
                Cancel
              </Button>
              <Button type="submit" variant="primary">
                Save Changes
              </Button>
            </div>
          </form>
        </Modal.Body>
      </StyledModal>

      {/* Delete Confirmation Modal */}
      <StyledModal
        show={showDeleteConfirm}
        onHide={() => {
          setShowDeleteConfirm(false);
          setFolderToDelete(null);
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Folder</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete "{folderToDelete?.name}"?</p>
          <p className="text-muted mb-0">
            This will remove all saved property references from this folder. The properties themselves won't be deleted.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => {
              setShowDeleteConfirm(false);
              setFolderToDelete(null);
            }}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteFolder}>
            Delete Folder
          </Button>
        </Modal.Footer>
      </StyledModal>

      {/* Remove Property Modal */}
      <StyledModal
        show={showRemovePropertyModal}
        onHide={() => {
          setShowRemovePropertyModal(false);
          setPropertyToRemove(null);
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Remove Property</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Remove this property from "{selectedFolder?.name}"?</p>
          <div className="bg-light p-3 rounded">
            <h6 className="mb-2">{propertyToRemove?.street_address}</h6>
            <p className="text-muted mb-0">
              {propertyToRemove?.city}, {propertyToRemove?.state} {propertyToRemove?.zip_code}
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => {
              setShowRemovePropertyModal(false);
              setPropertyToRemove(null);
            }}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={handleRemoveProperty}>
            Remove Property
          </Button>
        </Modal.Footer>
      </StyledModal>

      {/* Move Property Modal */}
      <StyledModal
        show={showMovePropertyModal}
        onHide={() => {
          setShowMovePropertyModal(false);
          setPropertyToMove(null);
          setTargetFolderId('');
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Move Property</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Select a destination folder:</p>
          <div className="bg-light p-3 rounded mb-3">
            <h6 className="mb-2">{propertyToMove?.street_address}</h6>
            <p className="text-muted mb-0">
              {propertyToMove?.city}, {propertyToMove?.state} {propertyToMove?.zip_code}
            </p>
          </div>
          <div className="mb-3">
            <label className="form-label fw-medium">Destination Folder</label>
            <select
              className="form-select"
              value={targetFolderId}
              onChange={(e) => setTargetFolderId(e.target.value)}
              required
            >
              <option value="">Choose a folder...</option>
              {folders
                .filter(f => f.id !== selectedFolder?.id)
                .map(folder => (
                  <option key={folder.id} value={folder.id}>
                    {folder.name}
                  </option>
                ))}
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => {
              setShowMovePropertyModal(false);
              setPropertyToMove(null);
              setTargetFolderId('');
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleMoveProperty}
            disabled={!targetFolderId}
          >
            Move Property
          </Button>
        </Modal.Footer>
      </StyledModal>
    </Container>
  );
};

// Wrap the component with SavedPropertiesProvider
const SavedPropertiesList = (props) => {
  return (
    <SavedPropertiesProvider>
      <SavedPropertiesListContent {...props} />
    </SavedPropertiesProvider>
  );
};

SavedPropertiesList.propTypes = {
  userProfile: PropTypes.object
};

SavedPropertiesList.defaultProps = {
  userProfile: null
};

export default SavedPropertiesList;