import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { FaCheck, FaTimes } from 'react-icons/fa';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const scaleIn = keyframes`
  from { transform: scale(0); }
  to { transform: scale(1); }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: ${props => props.success ? '#4CAF50' : '#F44336'};
  color: white;
  animation: ${fadeIn} 0.5s ease-out;
  padding: 2rem;
`;

const IconWrapper = styled.div`
  font-size: 64px;
  margin-bottom: 20px;
  animation: ${scaleIn} 0.5s ease-out;
`;

const Message = styled.h2`
  font-size: 24px;
  text-align: center;
`;

const SubscriptionResult = ({ success, message, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(onClose, 3000);
    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <Container success={success}>
      <IconWrapper>
        {success ? <FaCheck /> : <FaTimes />}
      </IconWrapper>
      <Message>{message}</Message>
    </Container>
  );
};

export default SubscriptionResult;