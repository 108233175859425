import React, { useState } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';

const Contact = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const subject = encodeURIComponent("Contact Form Submission");
    const body = encodeURIComponent(`Hello,\nI am ${name}. ${message}`);
    const mailtoLink = `mailto:info@agentspyglass.com?subject=${subject}&body=${body}&from=${email}`;
    window.location.href = mailtoLink;
    setShowPopup(false);
  };

  return (
    <>
      <section id="contact" className="contact-banner">
        <Container>
          <Row className="align-items-center">
            <Col md={8}>
              <h2>Get in Touch with Us</h2>
              <p>Have a suggestion on how we can make the website better? Please contact us here</p>
            </Col>
            <Col md={4} className="text-end">
              <p>Email: <a href="mailto:info@agentspyglass.com">info@agentspyglass.com</a></p>
              <Button variant="light" size="lg" onClick={() => setShowPopup(true)}>Contact Us</Button>
            </Col>
          </Row>
        </Container>
      </section>

      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-container">
            <div className="popup-content">
              <h2>Contact Us</h2>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Control 
                    type="text" 
                    placeholder="Your Name" 
                    value={name} 
                    onChange={(e) => setName(e.target.value)} 
                    required 
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control 
                    type="email" 
                    placeholder="Your Email" 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                    required 
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Control 
                    as="textarea" 
                    rows={4} 
                    placeholder="Your Message" 
                    value={message} 
                    onChange={(e) => setMessage(e.target.value)} 
                    required 
                  />
                </Form.Group>
                <Button type="submit" className="w-100 send-message-btn">
                  Send Message
                </Button>
              </Form>
            </div>
            <div className="popup-right">
              <button className="close-button" onClick={() => setShowPopup(false)}>
                <FaTimes />
              </button>
              <div className="popup-right-content">
                <p className="filler-text">We'd love to hear from you!</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Contact;