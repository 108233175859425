
import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomeCover from './components/HomeCover';
import Feed from './components/Feed';
import Contact from './components/Contact';
import Profile from './components/Profile';
import Home from './components/Home';
import SubscriptionSuccess from './components/SubscriptionSuccess';
import EmailVerificationPage from './components/EmailVerification';
import PasswordResetPage from './components/PasswordResetPage';
import AgentProfile from './components/AgentProfile';
import Agents from './components/Agents';
import { fetchUserProfile } from './utils/auth';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Layout from './components/Layout';

function App() {
  const [userProfile, setUserProfile] = useState(null);
  const [searchResults, setSearchResults] = useState(null);

  const updateUserProfile = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const profile = await fetchUserProfile();
        //console.log('Fetched user profile in App:', profile);
        setUserProfile(profile);
      } catch (error) {
        console.error('Error fetching user profile:', error);
        localStorage.removeItem('token');
        setUserProfile(null);
      }
    } else {
      setUserProfile(null);
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      updateUserProfile();
    }
  }, [updateUserProfile]);

  const handleUserProfileUpdate = useCallback((updatedProfile) => {
    //console.log('Updating user profile in App:', updatedProfile);
    setUserProfile(updatedProfile);
  }, []);
  
  const handleSearch = useCallback((results) => {
    setSearchResults(results);
  }, []);

  const handleResetSearch = useCallback(() => {
    setSearchResults(null);
  }, []);

  return (
    <Router>
      <Layout
        userProfile={userProfile}
        setUserProfile={setUserProfile}
        updateUserProfile={updateUserProfile}
      >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/Properties"
            element={
              <>
                <HomeCover
                  page="home"
                  userProfile={userProfile}
                  onSearch={handleSearch}
                  onResetSearch={handleResetSearch}
                />
                <Feed
                  userProfile={userProfile}
                  onUserProfileUpdate={handleUserProfileUpdate}
                  searchResults={searchResults}
                />
                <Contact />
              </>
            }
          />
          <Route
            path="/profile"
            element={
              <Profile
                userProfile={userProfile}
                setUserProfile={setUserProfile}
                updateUserProfile={updateUserProfile}
              />
            }
          />
          <Route path="/agents" element={<Agents />} />
          <Route 
  path="/agent/:id" 
  element={<AgentProfile currentUser={userProfile} />} 
/>
          <Route path="/subscription-success" element={<SubscriptionSuccess />} />
          <Route path="/verify-email/:uidb64/:token" element={<EmailVerificationPage />} />
          <Route path="/reset-password/:uidb64/:token" element={<PasswordResetPage />} />
        </Routes>
      </Layout>
      <ToastContainer />
    </Router>
  );
}

export default App;
